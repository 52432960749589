
import {gql} from "@apollo/client";

// gameRound(id: $id) {
//     roundId
//     expectedValue: decryptedValue
//     secretKey
// }

export const CONFIG_CLAIM = gql(`
    query CONFIG_CLAIM($address: String, $id: BigInt, $gameId: Int) {
        game(id: $gameId) {
            rounds(where:{roundId:$id}) {
                status
                secretKey
                roundId
                expectedValue: decryptedValue
                game {
                    id
                }
                players(where:{player:$address}) {
                    isClaimed
                    isWin
                    whitesCount
                    redsCount
                }
            }
        }
    }
`)
