import {NotificationType} from "./index.type";
import {ALL_ERRORS, ObjNotification} from "shared/config/textNotification";
import {notificationStore} from "shared/lib/notification";


export const catchErrorNotification = (
    textError: string,
    typeNotification: NotificationType
) => {
	const objNotification = ALL_ERRORS.find((error) => textError.includes(error.error));
	
	if(objNotification) {
		notificationStore.createNotification({
			text: objNotification.text,
			type: typeNotification
		})
	} else {
		notificationStore.createNotification({
			text: textError,
			type: typeNotification
		})
	}
	// return ALL_ERRORS.forEach((ERROR:ObjNotification) => {
  //       const isThisError:boolean = String(textError).includes(ERROR.error);
  //       if(!isThisError) return;
        
  //       notificationStore.createNotification({
  //           text: ERROR.text,
  //           type: typeNotification
  //       });
  //   })
}