
import { CloseBtn } from "../styled"
import { modalClose } from "shared/lib/modal"

interface CloseModalProps {
    isConnectWallet?:boolean;
}

export const CloseModal = ({isConnectWallet = false}:CloseModalProps) => {
    return(
        <CloseBtn onClick={modalClose(isConnectWallet)}>
            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.26696 1L11 10M1 10L10.733 1" stroke="#6EB9D4" strokeWidth="2"/>
            </svg>
        </CloseBtn>
    )
}