import {gql} from "@apollo/client";

export const IS_WINNER = (gql`
    subscription IS_WINNER($address: String) {
        root(id:0) {
            raffleCurrentRound {
                id
                winner:winners(
                    where:{
                        isClaimed:false,
                        player_:{id:$address}
                    }
                ) {
                    player {
                        id
                    }
                    isJackpot
                    isClaimed
                    amount
                }
            }
        }
    }
`)

export const TIME = (gql`
    query TIME {
        _meta { block { timestamp number } }
    }
`)