
import styled, { css } from 'styled-components';

export const  ContainerTable = String(css`
    width:100%;
    min-height: 250px;
    margin-top: 36px;
`)


export const TitleTable = styled.div`;
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-medium);
    line-height: 20px;
    color: var(--font-color-white100);
`

export const TableContent = styled.div`
  margin-top: 24px;

  width:100%;
  display: flex;
  flex-direction: column;
  gap:16px;

  overflow-y: auto;

  @media (max-width: 1000px) {
    gap: 8px;
  };
`

export const TableBodyItems = styled.div`
    display: flex;
    flex-direction: column;
    gap:12px;

    min-height: 296px;
    margin-bottom: 15px;
  
`

export const gridTableCSS = css`
    width: 100%;
    
    display: grid;
    grid-template-columns: repeat(5, 11.965811966%);
    grid-template-rows: 1fr;
    gap: 10px;

    align-items: center;
`

export const TitleNoData = styled.h4`;
  text-align: center;
  margin-top: 52px;
  font-size: var(--font-size-medium);
  color: var(--font-color-blue75);
  
`