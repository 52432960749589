
import { useCallback, useState } from "react";
import { useAccount, useContract, useSigner } from "wagmi";
import { parseEther } from "viem";
import ky from "ky";
import bs58 from "bs58";


import { paramsForBet } from "../../config";
import { CardType, ColorType } from "shared/config/gamesTypes";
import { transformColor, transformTypeCard } from "shared/lib/formatted";
import { gameIdVoteApi, server } from "shared/config/variables";
import { GameContract } from "shared/config/blockChain";
import { modalStore } from "shared/lib/modal";
import { BidModal } from "../../ui/BidModal";
import {useChange} from "shared/lib/useChange";
import {catchErrorNotification, NotificationType} from "shared/lib/notification";
import {REFERRER_KEY} from "shared/config/referral";


interface returnForm {
	currentColor: [ColorType, (newColor: ColorType) => () => void],
	rules: [boolean, () => void],
	confirm: () => Promise<boolean>,
}

export const useFormBet = (
	typeCard: CardType,
	gameid: number,
	roundId: number,
	setClose: () => void,
): returnForm => {

	const [activeColor, setActiveColor] = useState<ColorType>(ColorType.RED);
	const [rules, changeRules] = useChange();
	const { address } = useAccount();
	const {data:signer} = useSigner();
	const contract = useContract({ ...GameContract, signerOrProvider:signer })

	const changeActiveColor = useCallback((newColor: ColorType) => () => {
		setActiveColor(newColor);
	}, [activeColor]);

	const ConfirmBet = useCallback(async () => {
		if (!rules) return false;
		const getParams = await ky.post(`${server}${gameIdVoteApi(gameid, roundId)}`, {
			json: {
				vote: transformColor(activeColor),
				address,
			}
		}).json() as paramsForBet;

		const getReferrer = localStorage.getItem(REFERRER_KEY);
		const nullAdress = "0x0000000000000000000000000000000000000000"

		const referrerBytes = getReferrer !== "undefined" && getReferrer ? getReferrer : nullAdress;
		const referrer = referrerBytes === nullAdress ? nullAdress : `0x${Buffer.from(bs58.decode(referrerBytes)).toString("hex")}`

		try {
			await contract?.placeBet(
				getParams.gameId,
				getParams.roundId,
				referrer,
				Buffer.from(getParams?.roundPk ?? "", "hex"),
				Buffer.from(getParams?.voteData ?? "", "hex"),
				Buffer.from(getParams?.signature ?? "", "hex"),
				{ value: parseEther(`${+transformTypeCard(typeCard).price}`) }
			);

			setClose()

			modalStore.createModal({
				dataProps: {
					gameType: typeCard,
					idRoud: getParams.roundId,
					colorBid: transformColor(activeColor),
				},
				ModalElement: BidModal,
			});
		} catch (e: any) {
			console.log(e);
			catchErrorNotification(
				e.data.message,
				NotificationType.ERROR
			);
		}
		
		return true;
	}, [rules])

	return {
		currentColor: [activeColor, changeActiveColor],
		rules: [rules, changeRules],
		confirm: ConfirmBet
	}
}