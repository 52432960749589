import React from 'react';
import { CloseModal, ModalDefault } from 'shared/ui/Modal';
import { IconUI } from 'shared/ui/Icon';

import {
    BidModalWrapper,
    BidModalTitle,
    BidModalFields,
    BidModalField,
    BidModalInfo,
    BidModalValues,
    BidModalValueGame,
    BidModalValueID,
    BidModalValueColor,
    BidModalValueColorTitle,
    BidModalButton,
    BidModalButtonText,
} from './BidModal.styled';
import WhiteBall from './img/WhiteBall.svg';
import RedBall from './img/RedBall.svg';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import {CardType} from "shared/config/gamesTypes";
import {modalClose} from "shared/lib/modal";

interface BidModalProps {
    gameType:CardType,
    idRoud:number,
    colorBid:'white' | 'red';
}

export const BidModal = ({
    gameType,
    idRoud,
    colorBid,
}:BidModalProps) => {
    return (
        <ModalDefault>
            <BidModalWrapper>
                <CloseModal />
                <BidModalTitle>Bid placed</BidModalTitle>
                <BidModalInfo>
                    <BidModalFields>
                        <BidModalField>Game</BidModalField>
                        <BidModalField>ID round</BidModalField>
                        <BidModalField>Your bid</BidModalField>
                    </BidModalFields>
                    <BidModalValues>
                        <BidModalValueGame typeGame={gameType}>{gameType}</BidModalValueGame>
                        <BidModalValueID>#{idRoud}</BidModalValueID>
                        <BidModalValueColor>
                            <IconUI src={colorBid === 'red' ? RedBall : WhiteBall } />
                            <BidModalValueColorTitle>{colorBid}</BidModalValueColorTitle>
                        </BidModalValueColor>
                    </BidModalValues>
                </BidModalInfo>
                <BidModalButton>
                    <Button theme={ButtonTheme.OCEAN_BLUE} onClick={modalClose(false)}>
                        <BidModalButtonText>Ok</BidModalButtonText>
                    </Button>
                </BidModalButton>
            </BidModalWrapper>
        </ModalDefault>
    );
};
