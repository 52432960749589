import { createHistoryRouter, createRoute } from 'atomic-router';
import { createBrowserHistory } from 'history';
import { AppRoutes, RouteProps } from './type';

export const landingRoute = createRoute();
export const gameRoute = createRoute();
export const loginRoute = createRoute();
export const rewardRoute = createRoute();
export const statisticsRoute = createRoute();
export const single_statisticsRoute = createRoute();
export const raffleRoute = createRoute();
export const referralRoute = createRoute();
export const rulesRoute = createRoute();
export const faqRoute = createRoute();

export const touRoute = createRoute();
export const cookieRoute = createRoute();
export const disclaimerRoute = createRoute();



export const routesNavigation: Record<AppRoutes, RouteProps> = {
    [AppRoutes.LANDING]: {
        path: AppRoutes.LANDING,
        route: landingRoute,
    },
    [AppRoutes.LOGIN]: {
        path: AppRoutes.LOGIN,
        route: loginRoute,
    },
    [AppRoutes.GAME]: {
        path: AppRoutes.GAME,
        route: gameRoute,
    },
    [AppRoutes.REWARD]: {
        path: AppRoutes.REWARD,
        route: rewardRoute,
    },
    [AppRoutes.STATISTICS]: {
        path: AppRoutes.STATISTICS,
        route: statisticsRoute,
    },
    [AppRoutes.SINGLE_STATISTICS]: {
        path: (AppRoutes.SINGLE_STATISTICS + ":idGame/" + ":id") as AppRoutes,
        route: single_statisticsRoute,
    },
    [AppRoutes.RAFFLE]: {
        path: AppRoutes.RAFFLE,
        route: raffleRoute,
    },
    [AppRoutes.REFERRAL]: {
        path: AppRoutes.REFERRAL,
        route: referralRoute,
    },
    [AppRoutes.RULES]: {
        path: AppRoutes.RULES,
        route: rulesRoute,
    },
    [AppRoutes.FAQ]: {
        path: AppRoutes.FAQ,
        route: faqRoute,
    },
    [AppRoutes.TERMS_OF_USE]: {
        path: AppRoutes.TERMS_OF_USE,
        route: touRoute,
    },
    [AppRoutes.COOKIE_POLICY]: {
        path: AppRoutes.COOKIE_POLICY,
        route: cookieRoute,
    },
    [AppRoutes.DISCLAIMER]: {
        path: AppRoutes.DISCLAIMER,
        route: disclaimerRoute,
    },
};

export const routes: RouteProps[] = Object.entries(routesNavigation).map((route) => ({
    path: route[1].path,
    route: route[1].route,
}));

export const router = createHistoryRouter({
    routes,
});

const history = createBrowserHistory();
router.setHistory(history);
