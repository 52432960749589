import Metamask from "features/walletConnect/ui/modal/connectors/img/metamask.svg"
import Ledger from "features/walletConnect/ui/modal/connectors/img/ledger.svg"
import WalletConnect from "features/walletConnect/ui/modal/connectors/img/WalletConnect.svg"
import injected from "features/walletConnect/ui/modal/connectors/img/injected.svg"

export const walletIcon: Record<string, string> = {
    injected,
    walletConnect: WalletConnect,
    metaMask: Metamask,
    ledger: Ledger
}