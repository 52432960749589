import styled from 'styled-components';
import { gridTableCSS } from '../table.styled';
import { Link } from "atomic-router-react";

export const ContainerItem = styled.div
	.attrs((props: { css: string }) => props)`
    ${gridTableCSS}
    ${props => props.css}
	width: 100%;
    height: 24px;
`

export const Item = styled.div`
    height: 100%;
	width:100%;

    display: flex;
    align-items: center;
    gap: 8px;

		@media (max-width: 6000px) {
			gap: 2px;
		};
`

export const InfoImg = styled.div`
    min-height: 16px;
    min-width: 16px;

		@media (max-width: 1000px) {
			height: 12px;
    	width: 12px;
		};
		@media (max-width: 600px) {
			width: 4px;
			height: 4px;
		};
`

export const ItemText = styled.div
	.attrs((props: { color: string }) => props)`
		font-weight: 400;
		font-size: 16px;
		line-height: 12px;

	width:100%;
    color: ${props => props.color ?? 'var(--font-color-white100)'};

		@media (max-width: 1500px) {
			font-size: 12px;
			line-height: 10px;
		};

		@media (max-width: 1000px) {
			font-size: 10px;
			line-height: 10px;
		};

		@media (max-width: 400px) {
			font-size: 8px;
			line-height: 8px;
		};
`
export const ItemsBalance = styled.div`
		font-weight: 400;
    font-size: 12px;
    line-height: 14px;

		@media (max-width: 1000px) {
			font-size: 10px;
			line-height: 10px;
		};

		@media (max-width: 400px) {
			font-size: 8px;
			line-height: 8px;
		};
`

export const ItemLinkText = styled(Link)`
    font-weight: 400;
    font-size: var(--font-size-medium);
    line-height: 24px;
    color: var(--font-color-blue50);
    cursor: pointer;
	
	display: flex;
	align-items: center;
	justify-content: space-between;
    
    &:hover {
        color: var(--font-color-white100);
        transition: color 0.3s ease;
    }

	@media (max-width: 1000px) {
		font-size: 10px;
		line-height: 10px;
	};
`

export const LinkTypeGame = styled.div
	.attrs((props: {idGame:0|1|2}) => props)
	`
	@media(min-width: 1000px) {
		display: none;
	}
	
	margin-left:5px;
	min-height: 16px;
	min-width: 16px;
	border-radius:50%;
	border: 1px solid rgb(123, 133, 159);
	background: ${props => props.idGame === 0 ? 'var(--font-color-pink100)' : props.idGame === 1 ? 'var(--font-color-yellow100)' : 'var(--font-color-ultimate100)'};
`

export const CircleInfo = styled(InfoImg)
	.attrs((props: { color: string }) => props)`
    border-radius: 50%;

    background: ${props => props.color};
		background: ${props => props.color};

    border: 1px solid #7B859F;

		@media (max-width: 600px) {
			width: 4px;
			height: 4px;
		};
`

export const ParticipatedInner = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;

	color: #F2D877;

	@media (max-width: 1000px) {
		font-size: 0;
		
		width: 4px;
		height: 4px;
		background: #F2D877;
		border-radius: 12px;
	};
`

