
import {useAccount} from "wagmi";
import {useSubscription} from "@apollo/client";
import {formatEther} from "viem";

import {returnData} from "shared/lib/pagination";
import {ROUNDS_REWARDS_PLAYER, ROUNDS_REWARDS_GAMES, ROUNDS_REWARDS_BIDS} from "entities/user/config";
import {ClaimBtn} from "features/ClaimReward";
import {
    convertDate,
    formattedIntegers,
    transformColorTable,
    transformIntToName,
    transformTypeCard
} from "shared/lib/formatted";
import {transformProps} from "shared/config/sharedType";
import {isWinColor, isWinnerHelper} from "shared/lib/isWinner";
import {GameStatus} from "shared/config/gamesTypes";

export const useRewardsRounds = (skip: number): returnData => {
    const {address} = useAccount();
    const {data} = useSubscription(ROUNDS_REWARDS_BIDS, {
        variables: {address, skip}
    });

    const {data:dataGames} = useSubscription(ROUNDS_REWARDS_GAMES, {
        variables: {address}
    });

    const {data:dataPlayer} = useSubscription(ROUNDS_REWARDS_PLAYER, {
        variables: {address}
    });

    if (!data?.bids && !dataGames?.games && !dataPlayer?.player) return {
        items: [],
        countItems: 0
    }

    const countALlRound = +dataPlayer?.player?.gameRoundsCount - dataGames?.games.reduce((acc: number, currentGame: any) => {
        if (currentGame.currentRound?.bids?.[0]?.id) {
            return acc + 1
        }
        return acc
    }, 0);

    const items = data?.bids.map((bids: any) => {
        const {id, decryptedVote, round, } = bids;

        const {
            status,
            roundId,
            game,
            players,
            deadline,
        } = round;

        const isPlayer = players.length !== 0;
        const reds = isPlayer ? +players[0].redsCount : 0;
        const whites = isPlayer ? +players[0].whitesCount : 0;
        let statusbetUser = GameStatus.DRAW;

        if(reds < whites) {
            statusbetUser = GameStatus.WHITE;
        } else {
            statusbetUser = GameStatus.RED;
        }

        const betUser = isWinColor(statusbetUser);
        const isWinnerUser = isWinnerHelper({status, decryptedVote});
        const colorTable = transformIntToName(+game.id);
        const isClaimed = isPlayer && players[0].isClaimed;
        const winningAmount = isPlayer ? (formatEther(players[0].winningAmount)) : '';

        let roundIDType;
        switch (+game.id) {
            case 0:
                roundIDType = transformProps.TEXT_LINK_CLASSIC;
                break;
            case 1:
                roundIDType = transformProps.TEXT_LINK_PREMIUM;
                break;
            case 2:
                roundIDType = transformProps.TEXT_LINK_ULTIMATE;
                break;
        }

        const dataClaim = {
            encryptionStatus: bids.encryptionStatus,
            round: {
                redsCount: bids.round.redsCount,
                whitesCount: bids.round.whitesCount,
                roundId: bids.round.roundId,
                round: {
                    status,
                    game: {
                        id: bids.round.game.id,
                    }
                }
            },
            decryptedVote: bids.decryptedVote,
        }

        let typeIsStatus = transformProps.BALANCE;
        let textIsStatus;

        if(isWinnerUser.text === 'Draw') {
            textIsStatus = transformTypeCard(transformIntToName(+game.id).content).price;
        }
        if(isWinnerUser.text === 'Win') {
            textIsStatus = winningAmount;
        }
        if(isWinnerUser.text === 'Loose') {
            textIsStatus = '-'
            typeIsStatus = transformProps.STATUS_LOOSE;
        }

        let textIsClaimed
        let typeIsClaimed

        if (!isClaimed) {
            typeIsClaimed = transformProps.TEXT
            textIsClaimed = <ClaimBtn 
                data={dataClaim} 
                isLoose={isWinnerUser.text === 'Loose'}
            />
        } else {
            if (isWinnerUser.text === 'Win') {
                typeIsClaimed = transformProps.STATUS_WIN
                textIsClaimed = 'Reward received'
            } else if (isWinnerUser.text === 'Loose') {
                typeIsClaimed = transformProps.STATUS_LOOSE
                textIsClaimed = 'Ticket received'
            } else {
                typeIsClaimed = transformProps.STATUS_LOOSE
                textIsClaimed = 'Withdraw'
            }
        }


        return {
            id: id,
            items: [
                {
                    uniqId: 1,
                    text: convertDate(deadline),
                    type: transformProps.TEXT,
                },
                {
                    uniqId: 2,
                    text: roundId,
                    type: roundIDType,
                },
                {
                    uniqId: 3,
                    text: colorTable.content,
                    type: transformColorTable(colorTable.content),
                },
                {
                    uniqId: 4,
                    text: betUser.text,
                    type: betUser.element,
                },
                {
                    uniqId: 5,
                    text: isWinnerUser.text,
                    type: isWinnerUser.element,
                },
                {
                    uniqId: 6,
                    text: textIsStatus,
                    type: typeIsStatus,
                },
                {
                    uniqId: 7,
                    text: textIsClaimed,
                    type: typeIsClaimed,
                },

            ],
        }
    });

    return {
        items: items,
        countItems: countALlRound,
    };
}
