import {memo} from 'react'

import {BalanceText, ItemsBalance, ItemsBalanceCSS, TicketsImg} from "../../shared.styled";
import ticket from "./img/ticket.png";
import {formattedIntegers} from "shared/lib/formatted";
import {useTicketsUser} from "../../../model";
import {Skeleton} from "shared/ui/Skeleton";

export const TicketsBalance = memo(() => {
    const tickets = useTicketsUser();

    return(
        <Skeleton cssBody={ItemsBalanceCSS} isLoading={tickets === undefined}>
            <ItemsBalance>
                <TicketsImg>
                    <img src={ticket} />
                </TicketsImg>

                <BalanceText>{formattedIntegers(tickets)}</BalanceText>
            </ItemsBalance>
        </Skeleton>
    )
})