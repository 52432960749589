import {RouteInstance} from "atomic-router";

export enum AppRoutes {
    LANDING = "/",
    LOGIN = "/login",
    GAME = "/game",
    REWARD = "/reward",
    STATISTICS = "/statistics",
    REFERRAL = "/referral",
    SINGLE_STATISTICS = "/single_statistics/",
    RAFFLE = "/raffle",
    RULES = "/rules",
    FAQ = "/faq",
    TERMS_OF_USE = "/terms-of-use",
    COOKIE_POLICY = "/cookie-policy",
    DISCLAIMER = "/disclaimer",
}

export interface RouteProps {
    path: AppRoutes;
    route: RouteInstance<any>;
}

export interface LinkItem {
    route: AppRoutes | string | RouteInstance<any>;
    title: string;
}

export enum LinkHeaderType {
    RULES = "rules",
    FAQ = "faq",
}

export interface LinkHeaderItem extends LinkItem {
    type: LinkHeaderType;
}
