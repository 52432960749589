
import { memo } from "react";
import {ContainerTable, TableBodyItems, TableContent, TitleNoData, TitleTable} from "./table.styled"

import { TableProps } from "shared/config/sharedType";
import { HeadTable } from "./head";
import { ItemTable } from "./item";
import { Pagination } from "./Pagination";
import {Skeleton} from "shared/ui/Skeleton";

export const Table = memo(({
	title,
	items,
	headersTitle,
	gridCss,
	filtres,
	ignoredColumns = "",
	currentPage,
	totalPage,
	last,
	beforeLast,
	movePage,
	movePageSpecific,
}: TableProps) => {

	return (
		<Skeleton
			isLoading={!Boolean(items)}
			cssBody={ContainerTable}
		>
			<TitleTable>{title}</TitleTable>

			{filtres}

			<TableContent>
				<HeadTable
					headersTitle={headersTitle}
					gridCss={gridCss}
					ignoredColumns={ignoredColumns}
				/>

				<TableBodyItems>
					{items && items?.length ?
						<>
							{items.map((item) => {
								return (
									<ItemTable
										key={item.id}
										{...item}
										gridCss={gridCss}
										ignoredColumns={ignoredColumns}
									/>
								)
							})}
						</>
						:
						<TitleNoData>No data</TitleNoData>
					}
				</TableBodyItems>

			</TableContent>

			<Pagination
				currentPage={currentPage}
				totalPage={totalPage}
				last={last}
				beforeLast={beforeLast}
				movePage={movePage}
				movePageSpecific={movePageSpecific}
			/>
		</Skeleton>
	)
})