
import styled, {css} from 'styled-components';
import {SkeletonWrapper} from "shared/ui/Skeleton";

export const CardsContainer = styled.div`
    display: flex;

    margin-top: 32px;
    gap:30px;
  
  @media(max-width:1130px) {
    flex-direction: column;
    align-items: center;
  }
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(max-width:1130px) {
    max-width: 530px;
  }
`

export const CardInfoActive = String(css`
  min-height: 304px;
  
  @media(max-width: 1130px) {
    min-height: 210px;
  }
`)

export const CardInfoLast = String(css`
  min-height: 132px !important;
  margin-top: 18px;
`)