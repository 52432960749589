import {gql} from "@apollo/client";

export const LAST_GAMES = gql(`
	{
		games {
		    id
		    roundsCount
			rounds(orderBy: roundId, orderDirection: desc, skip: 1, first: 1) {
				rewardLevel
			  status
			  roundId
              id
              deadline
              startedAt
              playersCount
              redsCount
              whitesCount
              bidsCount
            }
		}
	}
`)
