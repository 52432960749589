import styled from "styled-components";

export const WalletConnect = styled.div`
  min-width: 140px;
  height: 100%;
  margin-left: auto;
`;
export const ButtonInner = styled.p`
  padding: 0 12px;
  @media (max-width: 1900px) {
    line-height: 14px;
  }
`;