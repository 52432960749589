
import {gql} from "@apollo/client";

export const ROUNDS_REWARDS_PLAYER = gql(`
  subscription RoundsRewardsPlayer($address: String) {
    player(id: $address) {
      gameRoundsCount
    }
  }
`);

export const ROUNDS_REWARDS_GAMES = gql(`
  subscription RoundsRewardsGames($address: String) {
      games {
        id
        currentRound {
          roundId
          bids(where: {player: $address}) {
            id
          }
        }
      }
    }
`);

export const ROUNDS_REWARDS_BIDS = gql(`
  subscription RoundsRewardsBids($address: String, $skip: Int) {
    bids(
      where: {player: $address, encryptionStatus: "DECRYPTED"}, 
      first:10, 
      skip:$skip,
      orderBy: placedAt
      orderDirection: desc
    ) {
      id
      encryptionStatus
      decryptedVote
      round {
        status
        roundId
        game {
          id
        }
        players(where: {player: $address}) {
          isClaimed
          winningAmount
          whitesCount
          redsCount
        }
        deadline
        redsCount
        whitesCount
      }
    }
  }
`)

export * from './index.type'

