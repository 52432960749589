import styled from 'styled-components';
import {CardType} from "shared/config/gamesTypes";

export const BidModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0px;
`;
export const BidModalTitle = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #e4ebfa;
`;
export const BidModalInfo = styled.div`
    margin-top: 24px;
    width: 60%;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
`;
export const BidModalFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #7b859f;
`;
export const BidModalField = styled.div``;
export const BidModalValues = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 16px;
`;
export const BidModalValueGame = styled.p
    .attrs((props: {typeGame:CardType}) => props)`
    color: var(--font-color-${
    (props) => 
      props.typeGame === CardType.CLASSIC ? 'pink100' :
      props.typeGame === CardType.PREMIUM ? 'yellow100' :
      'ultimate100'
    }) ;
`
export const BidModalValueID = styled.div``;
export const BidModalValueColor = styled.div`
    margin-right: 0px;
    text-align: center;
    width: 64px;
    height: 64px;
`;
export const BidModalValueColorTitle = styled.div`
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */
    /* white100 */
    color: #e4ebfa;
`;
export const BidModalButton = styled.div`
    margin: auto;
    margin-top: 46px;
`;
export const BidModalButtonText = styled.div`
    padding: 10px 57px;
`;
