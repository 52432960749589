import styled, { css } from "styled-components";

export const Blicks = css`
    position: absolute;
    z-index: 0;

    background: #7B859F;
    opacity: 0.3;
    filter: blur(150px);
    transform: rotate(22.28deg);
    width: 419.53px;
    height: 400px;
  @media(max-width: 1000px){
    display: none;
  }
`

export const BlicksTop = styled.div`
    ${Blicks};
    top:-200px;
    left:200px;
`

export const BlicksBottom = styled.div`
    ${Blicks};
    bottom:-250px;
    right:206px;
`