import { useStore } from "effector-react";

import { withProviders } from "./providers";
import { RoutesView } from "./providers/with-router";
import { router } from "shared/config/routeConfig";
import { AppUI } from "app/ui/app";

const Index = () => {
  const path = useStore(router.$path);

  if (path === "/") return <RoutesView />;

  return (
    <AppUI>
      <RoutesView />
    </AppUI>
  );
};

export default (withProviders(Index));
