import styled, {css} from "styled-components";

export const MobilePrizePoolWrapperCSS = String(css`
  width: 100%;
  height: 136px;
`)

export const MobilePrizePoolWrapper = styled.div`
    ${MobilePrizePoolWrapperCSS};
	order: 0;
`
export const PrizePoolInfo = styled.div`
	padding: 16px 24px;

	display: flex;
	justify-content: space-between;

	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
`
export const PrizePoolFieldNames = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`
export const PrizePoolValues = styled.div`
	display: flex;
	align-items: end;
	flex-direction: column;
	gap: 16px;
`
export const PrizePoolValueField = styled.div`
	color: #F2D877;
	font-weight: 600;
`
export const PrizePoolNextDrawField = styled.div`
	color: #7B859F;
`
export const PrizePoolTicketsField = styled.div`
	color: #7B859F;
`
export const PrizePoolMyTicketField = styled.div`
	font-weight: 600;
`
export const PrizePoolValueValue = styled.div`
	color: #F2D877;
	font-weight: 600;
`
export const PrizePoolNextDrawValue = styled.div`
	font-weight: 600;
`
export const PrizePoolTicketsValue = styled.div`
`
export const PrizePoolMyTicketValue = styled.div`
	font-weight: 600;
`