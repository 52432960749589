
export enum CardType {
    CLASSIC = 'Classic',
    PREMIUM = 'Premium',
    ULTIMATE = 'Ultimate',
}

export enum GameStatus {
    WHITE = 'WHITE',
    RED = 'RED',
    DRAW = 'NOBODY'
}


export enum ColorType {
    WHITE,
    RED
}