import styled, {css} from "styled-components";

export const ItemsBalanceCSS = String(css`
  height: 24px;
  width: 60px;
`)

export const ItemsBalance = styled.div`
    ${ItemsBalanceCSS};

    display: flex;
    align-items: center;
    gap:8px;
`


export const BalanceImg = styled.div`
    height: 100%;
    min-width: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
`
export const BnbImg = styled(BalanceImg)`
    background: #F3BA2F;
`

export const BalanceText = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: #E4EBFA;
`
export const TicketsImg = styled(BalanceImg)`
    background: linear-gradient(88.62deg, #776AC4 0%, #C8EBD3 100%);
`