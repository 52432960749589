import { useCallback, useEffect, useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { useStore } from "effector-react";
import { root } from "shared/config/variables";
import { Connector, useConnect } from "wagmi";

import SignClient from "@walletconnect/sign-client";
import { Web3Modal } from "@web3modal/standalone";

import {
  AppRoutes,
  gameRoute,
  loginRoute,
  router,
} from "shared/config/routeConfig";
import { convertAddress } from "shared/lib/formatted";
import { modalClose } from "shared/lib/modal";
import { projectId } from "shared/config/blockChain";
import { useWeb3Modal } from "@web3modal/react";

export const useWallet = () => {
  const { address } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const path = useStore(router.$path);

  const wallet = async () => {
    if (!address) {
      await loginRoute.open();
    }

    await disconnectAsync();
    await loginRoute.open();
  };

  useEffect(() => {
    if (!address || path !== AppRoutes.LOGIN) return;

    gameRoute.open();
    modalClose(false)();
  }, [address]);

  return {
    address: address && convertAddress(address),
    wallet,
  };
};

export interface uriData {
  uri?: string | undefined;
  approval: () => Promise<any>;
}

export const useGetURI_SUKA = () => {
  const [uri, setUri] = useState<uriData | undefined>(undefined);

  useEffect(() => {
    async function getURI() {
      const signClient = await SignClient.init({ projectId });

      const { uri, approval } = await signClient.connect({
        requiredNamespaces: {
          eip155: {
            methods: [
              "eth_sendTransaction",
              "eth_signTransaction",
              "eth_sign",
              "personal_sign",
              "eth_signTypedData",
            ],
            chains: ["eip155:1"],
            events: ["chainChanged", "accountsChanged"],
          },
        },
      });

      if (uri) setUri({ uri, approval });
    }

    getURI();
  }, []);

  return uri;
};

export const useConnectWallet = () => {
  const { connect, connectors } = useConnect();
  // const data = useGetURI_SUKA();
  const { open } = useWeb3Modal();

  const connectCurrentWallet = (
    connector: Connector<any, any, any> | undefined
  ) =>
    useCallback(async () => {
      if (!window?.ethereum?.isMetaMask && connector?.name === "MetaMask") {
        window.open(
          "https://metamask.app.link/dapp/" +
            document.URL.replace("http://", "")
        );
      }

      if (connector?.name === "WalletConnect") {
        root.classList.add("opacityWC");

        return open();

        // const web3Modal = new Web3Modal({
        //     walletConnectVersion: 2,
        //     projectId,
        //     standaloneChains: ['eip155:1']
        // })

        // if (data) {
        //     const {uri, approval} = data;

        //     try {
        //         web3Modal.openModal({ uri, standaloneChains: ['eip155:1'] })
        //         await approval();
        //         web3Modal.closeModal();
        //     } catch (err) {
        //       console.error(err);
        //     }
        // }
      }

      await connect({ connector });
    }, [connect]);

  return { connectCurrentWallet, connectors };
};
