
import {memo} from "react";

import { Card } from 'shared/ui/Card/Card';
import {
    RaffleParticipantsInfo,
    RaffleParticipantsTitle,
    RaffleParticipantsField,
    RaffleParticipantsSubtitle,
    RaffleParticipantsValue,
    RaffleParticipantsAddresses,
    RaffleParticipantsAddress,
    RaffleParticipantsWrapperCss,
} from './RaffleParticipants.styled';
import { RaffleWinnerCard } from '../RaffleWinnerCard';
import {convertAddress} from "shared/lib/formatted";
import {useGetCurrentRound} from "entities/Raffle";
import {Skeleton} from "shared/ui/Skeleton";


export const RaffleParticipants = memo(() => {

    const data = useGetCurrentRound();
    const winnerAmount = data.winners.winners;

    return (
        <Skeleton
            isLoading={data.roundId === undefined}
            cssBody={RaffleParticipantsWrapperCss}
        >
            <Card>
                <RaffleParticipantsInfo>
                    <RaffleParticipantsTitle>Results of the last draw</RaffleParticipantsTitle>
                    <RaffleParticipantsField>
                        <RaffleParticipantsSubtitle>
                            Tickets participated
                        </RaffleParticipantsSubtitle>
                        <RaffleParticipantsValue>{data.tickets}</RaffleParticipantsValue>
                    </RaffleParticipantsField>
                    <RaffleParticipantsField>
                        <RaffleParticipantsSubtitle>
                            Prize pool distributed
                        </RaffleParticipantsSubtitle>
                        <RaffleParticipantsValue>{data.amount} BNB</RaffleParticipantsValue>
                    </RaffleParticipantsField>
                    <RaffleWinnerCard
                        played
                        text="1st place"
                        winnerAddress={data.winners.winner1st}
                        value={`${data.winningAmount} BNB`}
                    />
                    <RaffleParticipantsField>
                        <RaffleParticipantsSubtitle>2-26th place</RaffleParticipantsSubtitle>
                        <RaffleParticipantsValue>{winnerAmount.length && winnerAmount[0].amount} BNB</RaffleParticipantsValue>
                    </RaffleParticipantsField>
                    <RaffleParticipantsAddresses>
                        {data.winners.winners.map((el:any) => (
                            <RaffleParticipantsAddress key={el.address}>{convertAddress(el.address)}</RaffleParticipantsAddress>
                        ))}
                    </RaffleParticipantsAddresses>
                </RaffleParticipantsInfo>
            </Card>
        </Skeleton>
    );
});
