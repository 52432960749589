import {NativeBalance} from "./nativeBalance";
import {TicketsBalance} from "./ticketBalance";
import {BodyBalances} from "./styled";


export const BalancesUser = () => {

    return(
        <BodyBalances>
            <TicketsBalance />

            <NativeBalance/>
        </BodyBalances>
    )
}