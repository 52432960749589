import {gql} from "@apollo/client";


export const ROUND_INFO = gql(`
   query infoRound($roundId: ID, $gameID: ID) {
        gameRounds(where: {game: $gameID, roundId: $roundId}) {
            status
            roundId
            encryptionStatus
            decryptedValue
            whitesCount
            redsCount
            startedAt
            revealedAt
            bidsCount
            deadline
            winningAmount
            rewardLevel
            game {
              id
            }
        }
   } 
`)