import {gql} from "@apollo/client";

export const ACTIVE_GAME_COUNT = gql(`
    query ACTIVE_GAME_COUNT($address: String) {
        games {
            id
            currentRound {
                bids(where: {player: $address}) {
                    id
                }
            }
        }
    }
`)