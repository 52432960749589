import { ButtonHTMLAttributes, ReactNode } from 'react';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './Button.module.css';

export enum ButtonTheme {
	GRAY_DEFAULT = 'grayDefault',
	OCEAN_DEFAULT = 'oceanDefault',
	OCEAN_BLUE = 'oceanBlue',
	WHITE_BLUE = 'whiteBlue',
	YELLOW_BLUE = 'yellowBlue',
	WHITE_CLASSIC = 'whiteClassic',
	WHITE_PREMIUM = 'whitePremium',
	WHITE_ULTIMATE = 'whiteUltimate',
	BLUE_DEFAULT = 'blueDefault',
	OUTLINED_BLUE = 'outlinedBlue',
	BLUE_GRADIENT = 'blueGradient',
	GOLD = "gold",
	NOT_ACTIVE = 'notActive',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	children?: ReactNode;
	theme?: ButtonTheme;
}

export const Button = (props: ButtonProps) => {
	const {
		className,
		theme = ButtonTheme.WHITE_BLUE,
		children,
		...otherProps
	} = props;

	return (
		<button
			type="button"
			className={classNames(cls.Button, {}, [className, cls[theme]])}
			{...otherProps}>
			<div className={cls.btnInner}>{children}</div>
		</button>
	);
};