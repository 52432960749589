

export const RAFFLE_MODAL_KEY = 'RAFFLE_MODAL';

export const GET_RAFFLE_IS_MODAL = JSON.parse(localStorage.getItem(RAFFLE_MODAL_KEY) as string);

export const SET_MODAL_IS_RAFFLE = (idRaffle:number) => {
    const oldArray = Array.isArray(GET_RAFFLE_IS_MODAL) ? GET_RAFFLE_IS_MODAL : [];
    const arrIsRaffleModal= JSON.stringify([...oldArray, {[idRaffle]:true}]);

    localStorage.setItem(RAFFLE_MODAL_KEY, arrIsRaffleModal);

}