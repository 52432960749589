
import styled from "styled-components";
import {isMobileProps} from "../styled";

export const BalanceRefferal = styled.p`
    font-weight: 600;
    font-size: var(--font-size-large);
    line-height: 24px;
    color: var(--font-color-white100);


  @media(max-width: 860px) {
    font-size: var(--font-size-small);
  }
`

export const BodyBalanceRefferal = styled.div
    .attrs((props:isMobileProps) => props)`
  display: ${props => props.isMobile ? 'none' : 'flex'};
  gap:12px;
  flex-direction: column;
  align-items: center;
  
  @media(max-width: 860px) {
    display: ${props => props.isMobile && 'flex'};
    flex-direction: row;
    justify-content: space-between;
  }
`


export const PossibleRewards = styled.span`
  font-weight: 400;
  font-size: var(--font-size-small);
  line-height: 14px;
  color: var(--font-color-blue75);
`