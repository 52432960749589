
import {memo} from "react";

import {useStore} from "effector-react";

import { FiltresTabs} from './styled';
import { Tab } from 'shared/ui/Tab';
import { tabs } from '../../../config';
import {$filtersGames} from "../../../model";

export const Filtres = memo(() => {
    const whyFilters = useStore($filtersGames);

    return(
        <FiltresTabs>
            {tabs.map((tab, index) => (
                <Tab
                    key={tab.tab.text}
                    {...tab}
                    active={whyFilters === index}
                />
            ))}
        </FiltresTabs>
    )
})