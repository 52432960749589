import { ReactNode } from 'react';

import { ModalContainer } from '../styled';
import { Modal } from './styled';
import { Card, CardOverflow } from '../../Card';

interface ModalDefaultProps {
    children: ReactNode;
}

export const ModalDefault = ({ children }: ModalDefaultProps) => {
    return (
        <Modal>
            <Card overflow={CardOverflow.VISIBLE}>
                <ModalContainer>{children}</ModalContainer>
            </Card>
        </Modal>
    );
};
