import {useEffect, useState} from "react";
import {usePagination} from "shared/lib/pagination/index";

export interface returnData {
    items:any[] | never[] | false,
    countItems:number,
}

type HookTake = (skip: number) => returnData

export const useMergePagination = (useHookTake:HookTake) => {
    const [skip, setSkip] = useState<number>(0)
    const data = useHookTake(skip);
    const dataPagination = usePagination(data.countItems);

    useEffect(() => {
        setSkip(dataPagination.currentPage*10)
    }, [dataPagination])

    return {
        pagination: dataPagination,
        data,
    }
}