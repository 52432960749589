import {gql} from "@apollo/client";

export const TOTAL_REFERRAL = gql(`
    query referralCount($address: String) {
        player(id: $address){
            referralsCount
            referrals {
                id
            }   
        }
    }
`)