
import {ClaimContainer} from "../styled"
import { Button, ButtonTheme } from "shared/ui/Button/Button"

import {useClaim, type bidClaim} from "../../model";

interface ClaimBtnProps {
    data:bidClaim;
    isLoose:boolean;
}


export const ClaimBtn = ({data, isLoose}:ClaimBtnProps) => {
    const {claimCall} = useClaim();

    return(
        <ClaimContainer>
            <Button
                onClick={claimCall(data)}
                theme={isLoose ? ButtonTheme.BLUE_GRADIENT : ButtonTheme.WHITE_CLASSIC}
            >
                Claim {isLoose ? 'tickets' : 'reward'}
            </Button>
        </ClaimContainer>
    )
}