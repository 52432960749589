import { useStore } from "effector-react";

import { Button, ButtonTheme } from "shared/ui/Button/Button";
import { ButtonInner } from "./WalletConnecBtn.styled";
import { useWallet } from "../model";
import { AppRoutes, router } from "shared/config/routeConfig";

import { useWeb3Modal } from "@web3modal/react";

interface WalletConnectBtnProps {
  typeColors?: ButtonTheme.BLUE_DEFAULT | ButtonTheme.BLUE_GRADIENT;
}

export const WalletConnectBtn = ({ typeColors }: WalletConnectBtnProps) => {
  const { wallet, address } = useWallet();
  const path = useStore(router.$path);

  return (
    <Button onClick={wallet} theme={typeColors ?? ButtonTheme.BLUE_DEFAULT}>
      <ButtonInner>
        {(path !== AppRoutes.LANDING && !address) || path === AppRoutes.LANDING
          ? "Launch app"
          : address}
      </ButtonInner>
    </Button>
  );
};
