
import styled, {css} from "styled-components";

export const QrCodeBodyCSS = String(css`
  width: 160px;
  height: 160px;
  margin: 0 auto;
  margin-top: 24px;
  border-radius:0;
`)

export const QrCodeBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: white;
`