import {memo, useEffect} from "react";
import AOS from 'aos';
import {LazyLayout} from "shared/ui/Loading";

export const aosInit = (component: () => React.ReactNode) => (() => {
    useEffect(() => {
        AOS.init({
            duration : 1000
        });
    }, []);

    return component() ;
})