
export interface isActive {
    isActive:boolean;
}

export type gameId = '1' | '2' | '3' | 1 | 2 | 3;

export enum encryptionStatus {
    ENCRYPTED='ENCRYPTED',
    DECRYPTING='DECRYPTING',
    DECRYPTED='DECRYPTED',
}


export * from './table'