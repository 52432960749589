import {gql} from "@apollo/client";

export const ROUNDS_ACTIVITY = gql(`
    query gameRounds($address: String, $skip: Int, $gameType: [String]) {
        gamesCount:games {
            id
            roundsCount
        }
        gameRounds(
            orderDirection: desc,
            orderBy: startedAt,
            skip: $skip,
            first: 10,
            where: {
                encryptionStatus_not: "ENCRYPTED", game_in: $gameType
            }
        ) {
            id
            status
            roundId
            deadline
            bidsCount
            whitesCount
            redsCount
            rewardLevel
            encryptionStatus
            game {
              id
            }
            bids(where: {player:$address}) {
                id
            }
        }
   }
`);

export * from './index.type'

