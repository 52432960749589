import {createEvent, createStore} from "effector";
import {NotificationI} from "./index.type";

function notificationState() {
    const createNotification = createEvent<NotificationI | null>()
    const $notification = createStore<NotificationI | null>(null)

    $notification.on(createNotification, (_, value) => value)

    return { createNotification, $notification };
}

export const notificationStore = notificationState();

export const deleteNotification = () => {
    notificationStore.createNotification(null)
}
export {type NotificationI, NotificationType} from './index.type'
export {catchErrorNotification} from './catchNewNotification'
