import {gql} from "@apollo/client";


export const GENERAL_PLATFORM = gql(`{
    root(id: "0") {
        playersCount
        roundsCount
        aggregate {
            winRedRoundsCount
            winWhiteRoundsCount
            rewardLevel
        }
    }
    
    games {
        roundsCount
    }
}`)