import { TextDescription, CopyText, QrCodeCopySS } from "../styled";

import QRCodeSVG from "qrcode.react";
import { useEffect, useState } from "react";

import { useGetURI_SUKA } from "../../../model";

import { QrCodeBody, QrCodeBodyCSS } from "./styled";
import { createQrCode } from "../../../lib/createQrCode";
import { connectorWC } from "shared/config/blockChain";
import { copyText } from "shared/lib/copyText";
import { useConnect } from "wagmi";
import { Skeleton } from "shared/ui/Skeleton";
import { root } from "shared/config/variables";

export const QrCodeWalletConnect = () => {
  const [qrCode, setQrCode] = useState<string>("");
  const { connect } = useConnect();

  useEffect(() => {
    const generateQRCodeAsync = async () => {
      root.classList.remove("opacityWC");

      createQrCode(connectorWC, (uri) => {
        console.log(uri);
        setQrCode(uri);
      });

      connect({ connector: connectorWC });
    };
    generateQRCodeAsync();
  }, [connect]);

  return (
    <>
      <TextDescription>
        Scan QR-code with a WalletConnect-compatible wallet
      </TextDescription>

      <Skeleton isLoading={qrCode === ""} cssBody={QrCodeBodyCSS}>
        <QrCodeBody>
          <QRCodeSVG value={qrCode ?? ""} />
        </QrCodeBody>
      </Skeleton>

      <Skeleton isLoading={qrCode === ""} cssBody={QrCodeCopySS}>
        <CopyText onClick={copyText(qrCode ?? "")}>Copy to clipboard</CopyText>
      </Skeleton>
    </>
  );
};
