
import { Card } from 'shared/ui/Card';
import { CardBorderRadius, CardTheme } from 'shared/ui/Card/Card';
import {
    RaffleParticipantsWinner,
    RaffleParticipantsWinnerInfo,
    RaffleParticipantsWinnerPlace,
    RaffleParticipantsWinnerAddress,
    RafflePlayed,
    RaffleParticipantsWinnerValue,
} from './RaffleWinnerCard.styled';

interface RaffleWinnerCardProps {
    text?: string;
    winnerAddress?: string;
    value?: string;
    played: boolean;
}

export const RaffleWinnerCard = (props: RaffleWinnerCardProps) => {
    const { winnerAddress, value, text = '1st place', played } = props;

    return (
        <RaffleParticipantsWinner>
            <Card theme={CardTheme.YELLOW} borderRadius={CardBorderRadius.SMALL}>
                <RaffleParticipantsWinnerInfo>
                    <RaffleParticipantsWinnerPlace>{text}</RaffleParticipantsWinnerPlace>
                    {winnerAddress?.length && (
                        <RafflePlayed>
                            <RaffleParticipantsWinnerAddress>
                                {winnerAddress}
                            </RaffleParticipantsWinnerAddress>
                        </RafflePlayed>
                    )}
                    {!played && (
                        <RaffleParticipantsWinnerAddress>
                            Not played
                        </RaffleParticipantsWinnerAddress>
                    )}
                    {value?.length && (
                        <RaffleParticipantsWinnerValue>{value}</RaffleParticipantsWinnerValue>
                    )}
                </RaffleParticipantsWinnerInfo>
            </Card>
        </RaffleParticipantsWinner>
    );
};
