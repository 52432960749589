import { AppRoutes } from 'shared/config/routeConfig';
import {
    CardLastGame,
    CardTitle,
    CircleBg,
    Info,
    Date,
    Header,
    RoundId,
    ItemInfoLeft,
    InfoMain,
    ItemInfoRight,
    InfoRight,
} from './styled';

import { CardsPrevious } from '../../../config';
import {formattedIntegers, transformIntToName} from "shared/lib/formatted";

export const LastGameCard = ({
    id,
    gameId,
    participated,
    winBid,
    rewardLevel,
    date
}: CardsPrevious) => {
    return (
        <CardLastGame to={`${AppRoutes.SINGLE_STATISTICS}${transformIntToName(gameId).title}/${id}`}>
            <CircleBg />

            <Header>
                <div>
                    <CardTitle>Last game</CardTitle>
                    <Date>{date}</Date>
                </div>

                <RoundId>#{id}</RoundId>
            </Header>

            <InfoMain>
                <Info>
                    <ItemInfoLeft>Participated</ItemInfoLeft>
                    <ItemInfoLeft>Winning bid</ItemInfoLeft>
                    <ItemInfoLeft>Reward level</ItemInfoLeft>
                </Info>

                <InfoRight>
                    <ItemInfoRight>{formattedIntegers(+participated)}</ItemInfoRight>
                    <ItemInfoRight>{winBid[0].toUpperCase() + winBid.slice(1)}</ItemInfoRight>
                    <ItemInfoRight>{formattedIntegers(+rewardLevel)}%</ItemInfoRight>
                </InfoRight>
            </InfoMain>
        </CardLastGame>
    );
};
