import { InfoImg, Item, ItemsBalance } from "../styled";

import { textProps } from "shared/config/sharedType";

import { IconUI } from "shared/ui/Icon";
import bnb from "shared/assets/images/bnb.svg";

export const Balance = ({ text }: textProps) => (
	<Item>
		<InfoImg>
			<IconUI
				src={bnb}
			/>
		</InfoImg>

		<ItemsBalance>{text}</ItemsBalance>
	</Item>
)
