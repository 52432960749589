import {ReactNode, Suspense} from "react";

import c from "./loading.module.css"

export const Loading = () => {
    return (
        <div className={c.bodyLoading}>
            <div className={c.ldsEllipsis}>
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}


export const LazyLayout = (({children}:{children:ReactNode}) => <Suspense fallback={<Loading />}>{children}</Suspense>)
