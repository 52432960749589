import { Link } from "atomic-router-react";
import { isActive } from "shared/config/sharedType";
import styled from "styled-components";
import { SocLinksImg } from "shared/ui/Icon";

export const Container = styled.div`
  padding: 12px 0 12px 12px;
  height: 100%;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Menu = styled.div`
  width: 72px;
  height: 100%;
  background: #202840;
  backdrop-filter: blur(8.5px);
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 25px 0 55px 0;
`;

export const LogoMenu = styled(Link)`
  width: 36px;
  height: 36px;
`;

export const MenuLinks = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 48px;

  margin-top: 48px;
`;

export const LinksImage = styled.div`
  width: 24px;
  height: 24px;

  margin-bottom: 12px;
`;

export const Linktext = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 12px;
`;

export const Links = styled(Link).attrs((props: isActive) => props)`
  position: relative;
  left: -1px;
  border-left: ${(props) => props.isActive && "2px solid #6EB9D4"};

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  transition: all 0.3s ease-in;

  & ${Linktext} {
    color: ${(props) => (props.isActive ? "#6EB9D4" : "#7B859F")};
  }

  & ${LinksImage} svg path {
    fill: ${(props) => (props.isActive ? "#6EB9D4" : "#7B859F")};
  }

  &:hover ${Linktext} {
    transition: all 0.3s ease-in;
    color: #6eb9d4;
  }
  &:hover ${LinksImage} svg path {
    transition: all 0.3s ease-in;
    fill: #6eb9d4;
  }
`;

export const MenuSocLinks = styled.div`
  margin-top: 120px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const SocLink = styled(Link)`
  cursor: pointer;
  transition: all 0.3s ease-in;
  width: 19px;
  height: 15px;

  ${SocLinksImg}
`;