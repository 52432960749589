import { Link } from "atomic-router-react"
import styled from "styled-components"

export const RaffleRulesWrapper = styled.div`
	display: flex;
	width: 31%;
	z-index: 1;

	@media (max-width: 1000px) {
		width: 100%;

		order: 3;
	};
	`
export const RaffleRulesInfo = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 302px;
	padding: 0 25px;
	z-index: 1;
`
export const RaffleRulesIcon = styled.div`
	position: absolute;

	top: 3px;
	left: -40px;

`
export const RaffleRulesInfoTitle = styled.div`
	margin-top: 20px;

	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #E4EBFA;
	z-index: 1;
`
export const RaffleRulesWarning = styled.div`
	margin-top: 18px;
	margin-left: 40px;

	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;

	position: relative;
	z-index: 1;
`
export const RaffleRulesLink = styled(Link)`
	color: #6EB9D4;
	z-index: 2;
`
export const RaffleRulesDistribution = styled.div`
	margin-top: 12px;
	display: flex;
	justify-content: space-between;
`
export const RaffleRulesTicketDistribution = styled.div`
	font-style: normal;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	font-weight: 400;
	font-size: 12px;
	line-height: 14px;

	color: #7B859F;
`
export const RaffleRulesTickets = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`
export const RaffleRulesTicketClassic = styled.div`
	font-style: normal;
	color: #D47ED6;
`
export const RaffleRulesTicketPremium = styled.div`
	font-style: normal;
	color: #F2D877;
`
export const RaffleRulesTicketUtimate = styled.div`
	font-style: normal;
	color: #8BD7DC;
`
export const RaffleRulesTicketReferrals = styled.div`
	font-style: normal;
	color: #E4EBFA;
`
export const RaffleRulesDistributionValues = styled.div`
	display: flex;
	gap: 24px;

	color: #7B859F;
`
export const RaffleRulesWin = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
`
export const RaffleRulesWinValues = styled.div`
	display: flex;
	flex-direction: column;
	align-items: end;
	gap: 12px;

	margin-top: 12px;
`
export const RaffleRulesWinValue = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #E4EBFA;
`
export const RaffleRulesLose = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
`
export const RaffleRulesLoseValues = styled.div`
	display: flex;
	flex-direction: column;
	align-items: end;
	gap: 12px;

	margin-top: 12px;
`
export const RaffleRulesLoseValue = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #E4EBFA;
`

export const RaffleRulesBetsPercentInfo = styled.div`
	margin-top: 25px;
	margin-left: 4px;

	display: flex;
	align-items: center;
`
export const RaffleRulesBetsPercent = styled.div`
	width: 24px;
	height: 12px;
`
export const RaffleRulesBetsSubtitle = styled.div`
	margin-left: 16px;

	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #E4EBFA;
`
export const RaffleRulesBetsNotification = styled.div`
	margin-top: 14px;

	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #7B859F;
`