import styled from "styled-components";

export const RaffleModalInfo = styled.div`
	display: flex;
	flex-direction: column;

	position: relative;
`
export const RaffleModalIcon = styled.div`
	position: absolute;
	top: -100px;
	left: 27%;
`
export const RaffleModalClose = styled.div`
	position: absolute;
	top: -30px;
	right: -18px;
`
export const RaffleModalTitle = styled.div`
	margin-top: 32px;

	text-align: center;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #E4EBFA;
`
export const RaffleTextWinnings = styled.div`
	margin: 22px 0 1px 0;

	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #7B859F;
`
export const RaffleModalText = styled.div`
	margin-top: 36px;

	text-align: center;

	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #7B859F; 
`
export const RaffleModalButton = styled.div`
  width: 140px;
  height: 40px;
  margin: 16px auto 0;

  display: flex;
  justify-content: center;
`
export const RaffleModalButtonText = styled.div`
	padding: 38px;
`
