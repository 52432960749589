import { MenuOpen, MenuOpenInside } from "./styled";
import { MenuMobileBtnProps } from "./config";

export const MenuBtn = ({ setClick, isMenu }: MenuMobileBtnProps) => {
  return (
    <MenuOpen onClick={setClick} isActive={isMenu}>
      {!isMenu ? (
        <>
          {[1, 2, 3].map((key) => (
            <MenuOpenInside key={key} />
          ))}
        </>
      ) : (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="#EDEDED"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L21 21M1 21L21 1"
            stroke={"#EDEDED"}
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </MenuOpen>
  );
};