
import styled from "styled-components";
import { Link } from "atomic-router-react";
import {FooterProps} from "./footer";
import {sizeMixin} from "shared/lib/mixinsCSS";

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 12px;
  margin-top: auto;
  padding-top: 48px;
`

export const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  gap:24px;

  @media (max-width: 1000px) {
    display: none;
  }
`

export const Links = styled(Link)`
  font-weight: 400;
  ${sizeMixin(12, 17, 1227)};
  ${sizeMixin(14, 19, 1227)};
  
  @media(max-width: 2100px) {
    font-size: 12px;
    line-height: 14px;
  }
  color: #E4EBFA;

  transition: all .3s ease-in;

  &:hover {
    color: #6EB9D4;
  }
`

export const ReservedText = styled.footer
    .attrs((props:FooterProps) => props)`
  display: block;
  width: 100%;
  font-weight: 400;
  ${sizeMixin(10, 15, 1227)};
  ${sizeMixin(12, 17, 1227)};
  text-align: center;
  color: ${props => props.isLanding ? '#444444' : '#7B859F'};

  @media(max-width: 2100px) {
    font-size: 10px;
    line-height: 12px;
  }
`