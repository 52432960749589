
import { Link } from 'atomic-router-react'
import styled from 'styled-components'


export const CardLastGame = styled(Link)`

    width: 100%;
    height: 132px;

    position:relative;
    overflow: hidden;

    background: #202840;
    backdrop-filter: blur(8.5px);
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;

    padding:12px 24px;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 16px; 
        padding: 1px; 
        background:linear-gradient(#FFFFFF45 27%,#FFFFFF45 0%, #202840); 
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
            mask-composite: exclude; 
    }
`

export const CircleBg = styled.div`
    position: absolute;
    right: -30px;
    bottom: -123px;

    background: #7B859F;
    opacity: 0.3;
    filter: blur(77.5px);
    transform: rotate(22.28deg);

    width: 203.48px;
    height: 194px;
`

export const Header = styled.div`
    width:100%;
    display: flex;
    justify-content: space-between;
`

export const CardTitle = styled.h2`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #E4EBFA;
`

export const Date = styled.h2`
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #7B859F;
    margin-top: 4px;
`

export const RoundId = styled.h1`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #6EB9D4;

		cursor: pointer;

		z-index: 100;
`

export const InfoMain = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:14px;
`

export const Info = styled.div`
    width:50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap:8px;
`

export const InfoRight = styled(Info)`
    justify-content: flex-end;

    & div {
        text-align:right;
    }
`

export const ItemInfo = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
`


export const ItemInfoLeft = styled(ItemInfo)`
    color: #7B859F;
`

export const ItemInfoRight = styled(ItemInfo)`
    color: #E4EBFA;
`