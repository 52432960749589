
import {Fragment} from "react";
import { ContainerItem } from './styled';

import { ItemProps } from 'shared/config/sharedType';
import { transformItemTablet } from './transform';

export const ItemTable = ({ items, gridCss, ignoredColumns }: ItemProps) => (
	<ContainerItem css={gridCss}>
		{items.map(({ text, type, uniqId  }, index) => {
			if (ignoredColumns?.includes(index.toString())) return;

			return (
				<Fragment key={uniqId}>
					{transformItemTablet(type, { text })}
				</Fragment>
			)
		})}
	</ContainerItem>
);
