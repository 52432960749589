import compose from "compose-function";
import { withRouter } from "./with-router";
import { withWagmi } from "./with-wagmi";
import {withApollo} from "./with-apollo";
import {sentry} from "./with-sentry";
import {withErrorBoundary} from "./with-errorBoundary";
import { modal } from "processes/Modal";
import {notification} from "processes/Notification";
import {protectedPages} from "processes/isConnected";
import {initWeb3Config} from "shared/config/blockChain";
import {addReferrer} from "processes/AddReferrer";
import {aosInit} from "processes/Aos";

export const withProviders = compose(
    sentry,
    withRouter,
    withWagmi,
    aosInit,
    withApollo,
    initWeb3Config,
    addReferrer,
    protectedPages,
    // withErrorBoundary,
    modal,
    notification,
);
