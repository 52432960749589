
import {
    RaffleModalButton,
    RaffleModalButtonText,
    RaffleModalClose,
    RaffleModalIcon,
    RaffleModalInfo,
    RaffleModalText,
    RaffleModalTitle,
		RaffleTextWinnings,
} from './RaffleModal.styled';
import { CloseModal, ModalDefault } from 'shared/ui/Modal';

import RaffleIcon from '../img/raffle.png';
import { IconUI } from 'shared/ui/Icon';
import { Button, ButtonTheme } from 'shared/ui/Button/Button';
import { RaffleWinnerCard } from 'entities/Raffle/ui/RaffleWidgets';
import {modalStore} from "shared/lib/modal";
import {rewardRoute} from "shared/config/routeConfig";

interface RaffleModalProps {
    amount:number,
    place:string,
}

export const RaffleModal = ({amount, place}:RaffleModalProps) => {

    const RaffleClose = () => {
        modalStore.createModal(null)
        rewardRoute.open();
    }

    return (
        <ModalDefault>
            <RaffleModalInfo>
                <RaffleModalIcon>
                    <IconUI src={RaffleIcon} />
                </RaffleModalIcon>

                <RaffleModalClose><CloseModal /></RaffleModalClose>

                <RaffleModalTitle>Congratulations you won the raffle</RaffleModalTitle>
                <RaffleTextWinnings>Your winnings</RaffleTextWinnings>

                <RaffleWinnerCard played={true} text={`${place}th place`} value={`${amount} BNB`} />

                <RaffleModalText>
                    You can collect your winnings in the Rewards section
                </RaffleModalText>

                <RaffleModalButton onClick={RaffleClose}>
                    <Button theme={ButtonTheme.WHITE_CLASSIC}>
                        <RaffleModalButtonText>Reward</RaffleModalButtonText>
                    </Button>
                </RaffleModalButton>
            </RaffleModalInfo>
        </ModalDefault>
    );
};
