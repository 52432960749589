import {gql} from "@apollo/client";

export const MY_BET_STATISTIC = gql(`
    query MY_BETS_STATISTIC($address: ID) {
      player(id: $address) {
        aggregate {
          whitesCount
          redsCount
        }
        gameRoundsCount
      }
    }
`)