import styled, {css} from "styled-components"

export const RafflePrizePoolCSS = String(css`
	width: 31%;
`)

export const RafflePrizePool = styled.div`
	display: flex;
	width: 100%;
`

export const RafflePrizePoolInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	height: 302px;
	padding: 0 25px;

	position: relative;

	@media (max-width: 600px) {
		height: 260px;
	};
`
export const RaffleImg = styled.div`
	position: absolute;
	top: -70px;
`
export const RaffePrizePoolValue = styled.div`
	margin-top: 80px;

	font-weight: 600;
	font-size: 30px;
	line-height: 32px;
	color: #F2D877;
`
export const RafflePrizePoolSubtitle = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #7B859F;

	margin-top: 5px;
`
export const RafflePrizePoolNextDraw = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 18px;
`
export const RafflePrizePoolNextDrawValue = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #E4EBFA;

	@media (max-width: 600px) {
		font-size: 12px;
		line-height: 14px;
	};
`
export const RafflePrizePoolTickets = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 12px;
`
export const RafflePrizePoolTicketsValue = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #E4EBFA;
`
export const RafflePrizePoolMyTicket = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 36px;

	@media (max-width: 600px) {
		margin-top: 18px;

		font-size: 12px;
		line-height: 14px;
	};
`
export const RafflePrizePoolTitle = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;

	color: #E4EBFA;

	@media (max-width: 600px) {
		font-size: 12px;
		line-height: 14px;
	};
`