
import  { css } from 'styled-components';


export const Card = String(css`
    width: 100%;
    height: 100%;
`).substring(1);

export const ItemsInfoCss = String(css`
    width: 33.33%;
`).substring(1);

export const grapthCss = String(css`
  width: 102px;
  height: 102px;

  @media(max-width: 1300px) {
    width: 94px;
    height: 94px;
  }
`).replace('.', '');

export const ClassicBet = String(css`
    color: var(--font-color-pink100);
`).replace('.', '');

export const PremiumBet = String(css`
    color: var(--font-color-yellow100);
`).replace('.', '');

export const UltimateBet = String(css`
    color: var(--font-color-ultimate100);
`).replace('.', '');

