import styled, {css} from "styled-components"

export const RaffleParticipantsWrapperCss = String(css`
	width: 31%;

	@media (max-width: 1000px) {
		width: 100%;
      
		order: 2;
	};
`)


export const RaffleParticipantsInfo = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 320px;
	padding: 0 25px;

	@media (max-width: 1000px) {
		height: 280px;
	};

	@media (max-width: 600px) {
		height: 300px;
	};
`
export const RaffleParticipantsTitle = styled.div`
	margin-top: 20px;

	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #E4EBFA;
`
export const RaffleParticipantsField = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 16px;
`
export const RaffleParticipantsValue = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;

	color: #E4EBFA;
`
export const RaffleParticipantsSubtitle = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;

	color: #7B859F;
`
export const RaffleParticipantsWinner = styled.div`
	margin-top: 16px;
`
export const RaffleParticipantsWinnerInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	width: 60%;
`
export const RaffleParticipantsWinnerPlace = styled.div`
	margin-left: 10px;

	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #E4EBFA;
`
export const RaffleParticipantsWinnerAddress = styled.div`
	display: flex;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;

	color: #E4EBFA;

	@media (max-width: 1000px) {
		font-size: 14px;
		line-height: 14px;
	};

	
`
export const RaffleParticipantsAddresses = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	gap: 4px 12px;

	width: 100%;
	margin: 16px 0px 0px 5px;
	
`
export const RaffleParticipantsAddress = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #7B859F;

	@media (max-width: 600px) {
		font-size: 9px;
		line-height: 10px;
	};

`