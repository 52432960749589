import { ReactNode } from "react";
import {ReturnData} from "shared/lib/pagination";

export enum transformProps {
	TEXT,
	BALANCE,
	TEXT_LINK_CLASSIC,
	TEXT_LINK_PREMIUM,
	TEXT_LINK_ULTIMATE,
	TEXT_CLASSIC,
	TEXT_GOLD,
	TEXT_ULTIMATE,
	BET_RED,
	BET_WHITE,
	STATUS_WIN,
	STATUS_LOOSE,
	PARTICIPATED
}

export interface ItemProps {
	id: number,
	gridCss?: string,
	ignoredColumns?: string,
	items: {
		uniqId:number,
		text: string | ReactNode,
		type: transformProps
	}[]
}

export interface HeadTableProps {
	headersTitle: string[][],
	gridCss?: string,
	ignoredColumns?: string;
}

export interface TableProps extends HeadTableProps, ReturnData {
	title: string;
	items: ItemProps[] | false;
	filtres?: ReactNode;
	ignoredColumns?: string;
}

export interface textProps {
	text: string | ReactNode;
}