
import {useAccount} from "wagmi";
import {useStore} from "effector-react";
import { useSubscription} from "@apollo/client";

import {ACTIVE_GAME_SUBSCRATION, CardsProps} from "entities/Games";
import {CardType} from "shared/config/gamesTypes";
import {$timeCurrent} from "shared/lib/timeCurrent";

export const useGetCurrentGames = () => {
	const {address} = useAccount();
	const time = useStore($timeCurrent);
	
	if(!address && !time) return [];
	const {data} = useSubscription(ACTIVE_GAME_SUBSCRATION, {
		variables:{address}
	});

	if(!data?.games) return [];

	const dataGames:CardsProps[] = data.games
		.map((game:any) => {
		const keyEnum:any= Object.keys(CardType)[game.id];
        let deadline;

        if(game.currentRound.deadline == 0) {
            deadline = 3600 * 1000 + time;
        } else {
            deadline = game.currentRound.deadline * 1000;
        }

		const player = game.currentRound.players?.[0]?.player;
		const bidsMax = !Number.isNaN(Number(player?.bidsMax)) ? player?.bidsMax - 1 : 1;

		return{
				id: game.id,
				deadline,
				tickets: Object(CardType)[keyEnum],
				title: Object(CardType)[keyEnum],
				current: {
					id: game.currentRound.roundId,
					participiants:game.currentRound.bidsCount,
					userValueDecrypted: 0,
					valueDecrypted: 0,
				},
				madeIsBid:Boolean(game.currentRound.bids?.[0]?.id),
				bidsMax,
				isModal:false,
		}
	});

	return dataGames;
}
