import {gql} from "@apollo/client";

export const INFO_CLAIM = gql(`
    query CLAIM_INFO($address: String) {
      player(id: $address) {
        aggregate {
            winningAmount
            winRoundsCount
            redsCount
            whitesCount
        }
        claimedAmount
        raffleWinningAmount
        raffleClaimedAmount
      }

      raffleRoundWinners(where:{player:$address}) {
        id
      }
    }
`)
