
import {gql} from "@apollo/client";

export const MODE_STATISTIC = gql(`
    query MODE_STATISTIC {
      games {
        id
        roundsCount
      }
    }
`)