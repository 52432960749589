import { Connector, useConnect } from "wagmi";
import { root } from "shared/config/variables";

import {
  ConnectorsBody,
  DesktopConnect,
  ImgConnect,
  ItemConnect,
  TitleConnect,
} from "./styled";
import { TextDescription } from "../styled";
import { IconUI } from "shared/ui/Icon";

import { walletIcon } from "../../../config";
import { useConnectWallet } from "../../../model";


export const Connectors = () => {
  const { connectCurrentWallet, connectors } = useConnectWallet();

  // const connectCurrentWallet =
  //   (connector: Connector<any, any, any> | undefined) => () => {
  //     if (connector?.name === "WalletConnect") {
  //       root.classList.add("opacityWC");
  //     }

  //     connect({ connector });
  //   };

  return (
    <>
      <TextDescription>Choose wallet you want to connect in</TextDescription>

      <DesktopConnect>
        <ConnectorsBody>
          {connectors.map((connector, index) => {
            if (connector?.name === "MetaMask" && index === 0) return;
            return (
              <ItemConnect
                key={connector.id}
                onClick={connectCurrentWallet(connector)}
              >
                <ImgConnect>
                  <IconUI src={walletIcon[connector.id]} />
                </ImgConnect>
                <TitleConnect>{connector.name}</TitleConnect>
              </ItemConnect>
            );
          })}
        </ConnectorsBody>
      </DesktopConnect>
    </>
  );
};
