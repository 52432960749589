import styled, {css} from "styled-components";

export interface isMobileProps {
    isMobile?:boolean;
}

export const BodyCSS = String(css`
  width: 100%;
  height: 100%;

  @media(max-width: 1320px) {
    max-width: 700px;
    margin: 0 auto;
  }
`)

export const Body = styled.div`
  ${BodyCSS}
`

export const Container = styled.div`
    width: 100%;
    max-height:100%;
    padding: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Header = styled.div`
    width:100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`

export const Title = styled.h2
    .attrs((props: {isClaim:boolean}) => props)`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--font-color-${props => props.isClaim ? "pink100" : "white100"});
`

export const BodyHeaderSum = styled.div`
  @media(max-width: 860px) {
    display: none;
  }
`


export const ConatinerBody = styled.div`

    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  @media(max-width: 860px) {
    flex-direction: column;
  }
`

export const ContentInfo = styled.div`
    width:50%;
    display: flex;
    flex-direction: column;
    gap:8px;


  @media(max-width: 860px) {
    width: 100%;
    gap:16px;
  }
`

export const ItemInfo = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const TitleInfo = styled.h3`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #7B859F;
  
  @media(max-width: 860px) {
    line-height: initial;
  }
`

export const Info = styled.h3`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #E4EBFA;
  @media(max-width: 860px) {
    line-height: initial;
  }
`

export const ClaimContainer = styled.div`
  width: 100%;
  height: 32px;
  & > div {
    width:100%;
  }
`


export const ClaimRaffleContainer = styled.div`
  width: 130px;
  height: 32px;
  margin-top: 16px;
`
