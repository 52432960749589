import {BalanceText, BnbImg, ItemsBalance} from "../../shared.styled";
import bnb from "shared/assets/images/bnb.svg";
import {useAccount, useBalance} from "wagmi";
import {chainId} from "shared/config/variables";
import {formattedIntegers} from "shared/lib/formatted";


export const NativeBalance = () => {
  const { address } = useAccount();
  const { data } = useBalance({
    address,
    chainId:+chainId
  });


  return(
      <ItemsBalance>
        <BnbImg>
          <img src={bnb} />
        </BnbImg>


        <BalanceText>{formattedIntegers(data?.formatted ?? '0')}</BalanceText>
      </ItemsBalance>
  )
}