import styled, {css} from "styled-components";
import { Link } from "atomic-router-react";

export const RaffleBannerCSS = String(css`
  max-width: 450px;
  margin: 28px auto 0;
`)

export const RaffleBannerMobileBody = styled.div`
  ${RaffleBannerCSS};
  margin-top: 0;
`

export const BodyInside = styled.div`
  padding: 16px 24px 24px;
`

export const Title = styled.h3`
  font-weight: 600;
  font-size:var(--font-size-medium);
  color: var(--font-color-white100);
`

export const Description = styled.h5`
  font-weight: 400;
  font-size:var(--font-size-small);
  color: var(--font-color-white100);
  margin-top: 12px;
`

export const BodyInfoTable = styled.div`
  width: 100%;
  margin-top: 16px;
  
  display: flex;
  flex-direction: column;
  gap:16px;
`

export const BodyInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TitleInfo = styled.h5`
  font-weight: 400;
  font-size: var(--font-size-small);
  line-height: 14px;
  color: var(--font-color-blue75);
`

export const TextInfo = styled.h5`
  font-weight: 600;
  font-size: var(--font-size-small);
  line-height: 14px;
  color: var(--font-color-white100);
`

export const PrizeInfo = styled(TextInfo)`
  color: var(--font-color-yellow100);
`

export const MoreDetails = styled(Link)`
  background: linear-gradient(265.33deg, #D9AF29 0%, #ECB12A 100%);
  
  width: 136px;
  height: 30px;
  
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 24px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  line-height: 14px;
	font-size: 12px;
`