
import { useEffect, useState } from "react";
import { Container, HeaderTitle } from "./styled";

import { HeadTableProps } from "shared/config/sharedType";
import {useisMobile} from "shared/lib/isMobile";

export const HeadTable = ({ headersTitle, gridCss, ignoredColumns }: HeadTableProps) => {
	const isMobile = useisMobile()

	return (
		<Container css={gridCss}>
			{headersTitle.map((title, index) => {
				if (ignoredColumns?.includes(index.toString())) {
					return;
				}
				const thisTitle = isMobile ? title[1] : title[0];

				return (
					<HeaderTitle key={thisTitle}>
						{thisTitle}
					</HeaderTitle>
				)
			})}
		</Container>)
}