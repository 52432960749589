import { createRoutesView } from "atomic-router-react";
import { ReactNode, Suspense } from "react";

import { GamePage } from "pages/Game";
import { RewardPage } from "pages/Reward";
import { StatisticsPage } from "pages/Statistics";
import { SingleStatisticsPage } from "pages/SingleStatistics";
import { RafflePage } from "pages/Raffle";
import { ReferralPage } from "pages/Referral";
import { RulesPage } from "pages/Rules";
import { FaqPage } from "pages/Faq";
import { RulesPlatformPage } from "pages/RulesPlatform";

import {
  landingRoute,
  loginRoute,
  gameRoute,
  rewardRoute,
  statisticsRoute,
  single_statisticsRoute,
  raffleRoute,
  referralRoute,
  rulesRoute,
  faqRoute,
  touRoute,
  cookieRoute,
  disclaimerRoute,
} from "shared/config/routeConfig";
import { ErrorPage } from "pages/Error";
import { LandingPage } from "pages/Landing";
import { LazyLayout } from "shared/ui/Loading";

export const RoutesView = createRoutesView({
  routes: [
    { route: landingRoute, view: LandingPage, layout: LazyLayout },
    { route: loginRoute, view: GamePage, layout: LazyLayout },
    { route: gameRoute, view: GamePage, layout: LazyLayout },
    { route: rewardRoute, view: RewardPage, layout: LazyLayout },
    { route: statisticsRoute, view: StatisticsPage, layout: LazyLayout },
    {
      route: single_statisticsRoute,
      view: SingleStatisticsPage,
      layout: LazyLayout,
    },
    { route: raffleRoute, view: RafflePage, layout: LazyLayout },
    { route: referralRoute, view: ReferralPage, layout: LazyLayout },
    { route: rulesRoute, view: RulesPage, layout: LazyLayout },
    { route: faqRoute, view: FaqPage, layout: LazyLayout },

    { route: touRoute, view: RulesPlatformPage, layout: LazyLayout },
    { route: cookieRoute, view: RulesPlatformPage, layout: LazyLayout },
    { route: disclaimerRoute, view: RulesPlatformPage, layout: LazyLayout },
    { route: disclaimerRoute, view: RulesPlatformPage, layout: LazyLayout },
  ],

  otherwise() {
    return (
      <LazyLayout>
        <ErrorPage />
      </LazyLayout>
    );
  },
});
