

import Countdown from "react-countdown";

import {
	BidMade, BidMadeText,
	BidMadeWrapper,
	Bids,
	BidsText,
	BottomCard,
	ContainerCard,
	ContainerContent,
	EndTime,
	EndTimeTextBottom,
	GameTicketMin,
	HeadCard,
	HeaderRightInfo,
	IdRound,
	MainBody,
	MiniText,
	PossibleReward,
	TopCard,
	TypeCard,
} from './CardGame.styled';

import {CardsProps, CardTickets, CardTypeBG} from '../../../config';
import { transformTypeCard } from "shared/lib/formatted";

import {CloseModal} from "shared/ui/Modal";
import {rendererTimerGames} from "shared/ui/timer";
import {WhyOpen} from "./isOpen";



export const CardGame = (props: CardsProps) => {
	const {
		current,
		deadline,
		title,
		madeIsBid,
		isModal,
	} = props;

	return (
		<ContainerCard color={CardTypeBG[title]} isModal={isModal}>
			<TopCard />
			<BottomCard />

			{isModal && <CloseModal />}

			<HeadCard>
				<MiniText>
					ID round
					<IdRound>{current.id}</IdRound>
				</MiniText>

				<HeaderRightInfo>
					<TypeCard>{title}</TypeCard>
					<GameTicketMin>Tickets min {CardTickets[title.toUpperCase() as any]}</GameTicketMin>
				</HeaderRightInfo>
			</HeadCard>

			{madeIsBid &&
				<BidMadeWrapper>
					<BidMade>
						<BidMadeText>
							The bid is made
						</BidMadeText>
					</BidMade>
				</BidMadeWrapper>
			}

			<MainBody>
				<ContainerContent>
					<EndTime>
						<Countdown
							date={deadline}
							renderer={rendererTimerGames}
						/>
					</EndTime>

					<EndTimeTextBottom>Until the end of the round</EndTimeTextBottom>
				</ContainerContent>

				<PossibleReward >Possible reward up to {transformTypeCard(title).possibleReward}%</PossibleReward>

				<ContainerContent mobileOrder={'2'} isModal={isModal}>
					<Bids>{current.participiants}</Bids>
					<BidsText>Bids</BidsText>
				</ContainerContent>

				<WhyOpen 
					{...props}
				/>
			</MainBody>
		</ContainerCard>
	);
};
