import styled from "styled-components";

export const DesktopConnect = styled.div`
  width: 100%;
  height: 222px;

  background: #202840;
  border-radius: 12px;

  padding: 24px 12px 24px 24px;
  margin-top: 15px;
`;

export const ConnectorsBody = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  //CHANGED
  justify-content: center;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--font-color-blue50);
    border-radius: 20px;
    cursor: pointer;
  }
`;

export const TitleConnect = styled.h2`
  font-weight: 400;
  font-size: var(--font-size-small);
  line-height: 14px;
  color: var(--font-color-white100);
`;

export const ItemConnect = styled.div`
  height: 24px;

  display: flex;
  align-items: center;
  gap: 16px;

  cursor: pointer;

  &:hover ${TitleConnect} {
    color: var(--font-color-blue50);
  }
`;

export const ImgConnect = styled.div`
  width: 24px;
  height: 100%;
`;
