import {gql} from "@apollo/client";


export const INFO_RAFFLE = gql(`
    query Info_Raffle_Reward($address: String) { 
      root(id:0) {
        jackpotBank
        raffleCurrentRound {
          id
          amount
          deadline
          playersCount
          ticketsSum
          winningAmount
          
          players(where: {player: $address}) {
            tickets
          }
          
          jackpot:winners(where:{isJackpot:true}) {
              player {
                id
              }
              amount
              isClaimed
            }
            winners(where:{isJackpot:false}) {
              player {
                id
              }
              amount
              isClaimed
          }
        }
      }
    }
`)