
import {useEffect, memo} from "react";

import { useChange } from 'shared/lib/useChange';

import { Participate } from './Participate';
import { BetPlaced } from './BetPlaced';
import {modalStore} from "shared/lib/modal";
import {CardsProps} from '../../../../config';
import {CardGame} from '../CardGame'

export const WhyOpen = memo(({
	idCard,
	current,
	tickets,
	deadline,
	title,
	bidsMax,
	isModal,
}: CardsProps) => {
    const [isOpen, setOpen] = useChange();


	useEffect(() => {
		if(isOpen && window.innerWidth <= 1330) {

			if(typeof(idCard) === 'undefined') return;

			modalStore.createModal({
				ModalElement:CardGame,
				dataProps:{
					idCard,
					id:current.id,
					current,
					tickets,
					deadline,
					title,
					isModal:true
				}
			})
		}
	}, [isOpen]);

    if((isOpen && window.innerWidth >= 1330) || (window.innerWidth <= 1330 && isModal)) return <BetPlaced
        typeCard={title}
        gameId={Number(idCard)}
        roundId={current.id}
        setClose={setOpen}
    />
    
    return <Participate setOpen={setOpen} typeCard={title} bidsMax={bidsMax} />
})