import {
  AppRoutes,
  LinkItem,
  faqRoute,
  gameRoute,
  raffleRoute,
  referralRoute,
  rewardRoute,
  rulesRoute,
  statisticsRoute,
  LinkHeaderType,
  LinkHeaderItem,
} from "shared/config/routeConfig";

export type {
    LinkHeaderItem,
    LinkHeaderType,
};

export const LinkHeaders: LinkHeaderItem[] = [
  {
    route: rulesRoute,
    title: "Rules",
    type: LinkHeaderType.RULES,
  },

  {
    route: faqRoute,
    title: "FAQ",
    type: LinkHeaderType.FAQ,
  },
];


export const LinkMenu: LinkItem[] = [
  {
    route: gameRoute,
    title: "Game",
  },

  {
    route: rewardRoute,
    title: "Reward",
  },

  {
    route: statisticsRoute,
    title: "Statistic",
  },

  {
    route: referralRoute,
    title: "Referral",
  },

  {
    route: raffleRoute,
    title: "Raffle",
  },
];