import styled from "styled-components";

export const BodyBalances = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  gap:24px;
  margin-left:auto;

  @media(max-width: 1250px) {
    gap: 20px;
    margin-left:0;
  }

  @media(max-width: 1000px) {
    max-width: 128px;
    gap: 24px;
    margin:48px auto 24px;
    justify-content: space-between;
  }
`