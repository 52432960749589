import { useChange } from "shared/lib/useChange";
import { CloseModal, ModalDefault } from "shared/ui/Modal";
import {
  ConatinerHead,
  VarintConnect,
  WalletConnectBody,
  IsChoose,
} from "./styled";

import { Connectors } from "./connectors";
import { QrCodeWalletConnect } from "./qr-code";

export const WalletConnect = () => {
  const [isChoose, setIsChoose] = useChange();

  return (
    <ModalDefault>
      <CloseModal isConnectWallet /> 

      <WalletConnectBody>
        <ConatinerHead>
          <VarintConnect isActive={isChoose} onClick={setIsChoose}>
            <span>QR Code</span>

            <IsChoose />
          </VarintConnect>

          <VarintConnect isActive={!isChoose} onClick={setIsChoose}>
            <span>Desktop</span>

            <IsChoose />
          </VarintConnect>
        </ConatinerHead>

        {isChoose ? <QrCodeWalletConnect /> : <Connectors />}
      </WalletConnectBody>
    </ModalDefault>
  );
};
