import { Connector } from "wagmi";

export async function createQrCode(
  connector: Connector,
  onUri: (uri: string) => void
) {
  const provider = await connector.getProvider();

  provider.once("display_uri", (uri: string) => {
    onUri(uri);
  });
	// let uri = `wc:`
	// onUri(uri)
}
