
import { CardType } from "shared/config/gamesTypes";
import { transformTypeCard } from "shared/lib/formatted";
import { Button } from "shared/ui/Button";
import {ParticipateBtn, ParticipateBtnText, ParticipateWrapper, UpToBids} from "./styled"

interface Participate {
    setOpen:() => void;
    typeCard:CardType;
    bidsMax:number,
}

export const Participate = ({setOpen, typeCard, bidsMax}:Participate) => {

    return(
        <ParticipateWrapper>
            <ParticipateBtn>
                <Button onClick={setOpen} theme={transformTypeCard(typeCard).theme} >
                    <ParticipateBtnText>
                        Participate for {transformTypeCard(typeCard).price} BNB
                    </ParticipateBtnText>
                </Button>
            </ParticipateBtn>
            
            <UpToBids>Up to {bidsMax} bids</UpToBids>
        </ParticipateWrapper>
    )
}