import { useAccount } from "wagmi";
import { memo } from "react";

import {
  BottomWeb3,
  Br,
  LinkRulesPlatform,
  LinksRulesPlatformBody,
  MainLinks,
  MainLinksBody,
  MenuWrapper,
  SocLinks,
  SocLinksBody,
  WalletConnectBody,
} from "./styled";

import { MenuMobileProps } from "./config";
import { MenuBtn } from "./menuBtn";
import { linksRulesPlatform } from "shared/config/routeConfig";
import { socLinks } from "shared/config/cosLinks";
import { WalletConnectBtn } from "features/walletConnect";
import { Button, ButtonTheme } from "shared/ui/Button/Button";
import { BalancesUser } from "entities/user";

export const Menu = memo(
  ({ isActive, setClick, links, where }: MenuMobileProps) => {
    const account = useAccount();

    return (
      <MenuWrapper isActive={isActive}>
        <MenuBtn isActive={isActive} setClick={setClick} isMenu />

        <MainLinksBody>
          {links.map((link) => (
            <MainLinks to={link.route} onClick={setClick}>
              {link.title}
            </MainLinks>
          ))}
        </MainLinksBody>

        <Br />

        <BottomWeb3>
          {where === "game" && <BalancesUser />}

          <WalletConnectBody>
            <WalletConnectBtn
              typeColors={
                account.address
                  ? ButtonTheme.BLUE_DEFAULT
                  : ButtonTheme.BLUE_GRADIENT
              }
            />
          </WalletConnectBody>
          {/* <a href="https://tokensale.whitered.io"> - 
            <Button theme={ButtonTheme.GOLD}>
              Token Sale
              <svg
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4141 4.99997L7.70706 0.292972C7.61422 0.200127 7.504 0.126478 7.38269 0.076231C7.26138 0.0259839 7.13136 0.000122071 7.00006 0.00012207C6.86876 0.000122069 6.73874 0.0259839 6.61744 0.076231C6.49613 0.126478 6.38591 0.200127 6.29306 0.292972C6.20022 0.385816 6.12657 0.496039 6.07632 0.617346C6.02607 0.738653 6.00021 0.868669 6.00021 0.999971C6.00021 1.13127 6.02607 1.26129 6.07632 1.3826C6.12657 1.5039 6.20022 1.61413 6.29306 1.70697L8.58606 3.99997L1.00006 3.99997C0.734846 3.99997 0.480492 4.10533 0.292955 4.29286C0.105419 4.4804 6.29425e-05 4.73475 6.29425e-05 4.99997C6.29425e-05 5.26519 0.105419 5.51954 0.292955 5.70708C0.480492 5.89461 0.734846 5.99997 1.00006 5.99997L8.58606 5.99997L6.29306 8.29297C6.19988 8.38562 6.12593 8.49579 6.07547 8.61712C6.02501 8.73845 5.99903 8.86856 5.99903 8.99997C5.99903 9.13138 6.02501 9.26149 6.07547 9.38282C6.12593 9.50416 6.19988 9.61432 6.29306 9.70697C6.38585 9.79992 6.49605 9.87365 6.61737 9.92397C6.73868 9.97428 6.86873 10.0002 7.00006 10.0002C7.1314 10.0002 7.26144 9.97428 7.38276 9.92397C7.50407 9.87365 7.61427 9.79992 7.70706 9.70697L12.4141 4.99997Z"
                  fill="black"
                />
              </svg>
            </Button>
          </a> */}
        </BottomWeb3>

        <Br />

        <SocLinksBody>
          {socLinks.map((link) => (
            <SocLinks
              key={link.title}
              to={link.link}
              target="_blank"
              onClick={setClick}
            >
              {link.img}
            </SocLinks>
          ))}
        </SocLinksBody>

        <LinksRulesPlatformBody>
          {linksRulesPlatform.map((link) => (
            <LinkRulesPlatform
              key={link.title}
              to={link.link}
              onClick={setClick}
            >
              {link.title}{" "}
            </LinkRulesPlatform>
          ))}
        </LinksRulesPlatformBody>
      </MenuWrapper>
    );
  }
);

export { MenuBtn } from "./menuBtn";
