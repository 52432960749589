import {useState} from "react";
import {ReturnData} from "./index.type";

const initReturn = {
    currentPage:0,
    totalPage:0,
    last:0,
    beforeLast:0,
    movePage:(isNext?: boolean) => () => {},
    movePageSpecific:(id:number) => () => {},
}

export const usePagination = (totalElementsInit:number | undefined):ReturnData => {
    const [currentPage, setCurrentPage] = useState<number>(0);

    let totalPage = 0;
    if(typeof (totalElementsInit) !== 'number') return initReturn;

    totalPage = Math.ceil(totalElementsInit / 10);
    const last = totalPage-1;
    const beforeLast = totalPage - 2;

    const movePage = (isNext?: boolean) => () => {
        if(isNext && currentPage+1 < totalPage) {
            return setCurrentPage((prevState) => prevState+1);
        }
        if(isNext) return ;

        if(currentPage !== 0) {
            return setCurrentPage((prevState) => prevState-1);
        }
    }

    const movePageSpecific = (id:number) => () => {
        setCurrentPage(id);
    }

    return {
        currentPage,
        totalPage,
        last,
        beforeLast,
        movePage,
        movePageSpecific,
    }
}

export type {ReturnData};
export {useMergePagination, type returnData} from './merger'