import {gql} from "@apollo/client";

export const GET_BIDS = gql(`
    query getBids($address: String) {
        bids(where: {player: $address, encryptionStatus:"DECRYPTED"}) {
            id
            encryptionStatus
            encryptedVote
            decryptedVote
            round {
                status
                game {
                    id
                }
                roundId
                redsCount
                whitesCount
             }
        }
    }
`)