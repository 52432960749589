import {gql} from "@apollo/client";


export const ACTIVE_GAME_SUBSCRATION = gql(`
	subscription ACTIVE_GAME($address: String) {
    games {
		  id
			currentRound {
	  	  roundId
			  deadline
				bidsCount
			  bids(where: {player: $address}) {
          id
        }
        players(where: {player: $address}) {
          player {
            bidsMax
          }
        }
			}
	  }
  }
`)