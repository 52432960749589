
import {Fragment} from "react";
import {useStore} from "effector-react";

import {BodyNotification} from "./styled";
import {configColors} from "shared/ui/Notification/configUI";

import {deleteNotification, notificationStore} from "shared/lib/notification";
import {useEffect} from "react";

export const Notification = () => {
    const stateNotification = useStore(notificationStore.$notification);

    useEffect(() => {
        if(stateNotification) {
            setTimeout(() => {
                deleteNotification?.()
            }, 7000)
        } else {
            deleteNotification?.();
            notificationStore
                .createNotification(stateNotification)
        }
    }, [stateNotification])

    if(!stateNotification) return null;

    return(
        <BodyNotification theme={configColors[stateNotification.type]}>
            {Array.isArray(stateNotification.text) ?
                <Fragment>
                    {stateNotification.text.map((textElement) => (
                        <Fragment key={textElement?.toString()}>{textElement}</Fragment>
                    ) )}
                </Fragment>
                :
                stateNotification.text
            }
        </BodyNotification>
    )
}

export {NotificationText} from './styled'