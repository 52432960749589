import {BodyPagination, PaginationBtn, PaginationBtnPage} from "./styled";
import {ReturnData} from "shared/lib/pagination";

export const Pagination = ({
    last,
    beforeLast,
    currentPage,
    movePage,
    movePageSpecific,
}:ReturnData) => (
    <BodyPagination>
        <PaginationBtn onClick={movePage(false)}>
            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.3219 0.353271L1.64956 5.01426L6.31055 9.6866" stroke="#7B859F"/>
            </svg>
        </PaginationBtn>


        <PaginationBtnPage isActive>{currentPage + 1}</PaginationBtnPage>
        {currentPage < beforeLast-1 &&
            <PaginationBtnPage>...</PaginationBtnPage>
        }
        {currentPage < beforeLast &&
            <PaginationBtnPage onClick={movePageSpecific(beforeLast)} >{beforeLast + 1}</PaginationBtnPage>
        }
        {currentPage < last &&
            <PaginationBtnPage onClick={movePageSpecific(last)} >{last+1}</PaginationBtnPage>
        }

        <PaginationBtn onClick={movePage(true)}>
            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.655151 0.542114L5.31614 5.21445L0.643802 9.87544" stroke="#7B859F"/>
            </svg>
        </PaginationBtn>
    </BodyPagination>
)