import { useState } from "react";

export type useIsChangeReturn = [boolean, () => void];

export const useChange = (): useIsChangeReturn => {
    const [state, setState] = useState(false);

    const onChangeState = () => {
        setState(!state);
    }
    
    return [state, onChangeState]
}