import { Link } from "atomic-router-react";
import styled, {css} from "styled-components";

export const RaffleBannerCSS = String(css`
  width: 100%;
  height: 112px;
  margin-top: 61px;
`)

export const RaffleBannerWrapper = styled.div`
  
    ${RaffleBannerCSS};
    margin-top: 0;

	display: flex;

	position: relative;
`
export const RaffleBannerIcon = styled.div`
	width: 150px;
	height: 150px;

	position: absolute;
	top: -44px;
	left: 20px;

	@media (max-width: 1300px) {
		width: 140px;
		height: 140px;
		left: 0px;
	};
`

export const RaffleBannerInfo = styled.div`
	margin-left: 15%;
	padding-right: 30px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
`
export const RaffleBannerDescription = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`
export const RaffleBannerTitle = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #E4EBFA;
`
export const RaffleBannerText = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #E4EBFA;
`
export const RaffleBannerPrizePool = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
export const RaffleBannerPrizePoolValue = styled.div`
	font-weight: 600;
	font-size: 30px;
	line-height: 32px;
	color: #F2D877;
`
export const RaffleBannerPrizePoolSubtitle = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #7B859F;
`
export const RaffleBannernNextDraw = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
export const RaffleBannernNextDrawTitle = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #7B859F;
`
export const RaffleBannernNextDrawValue = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #E4EBFA;
`
export const RaffleLink = styled(Link)`
	padding: 0 10px;
`

export const MoreDetailsBtn = styled.div`
  width: 140px;
  height:40px;
`