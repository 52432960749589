
import styled from "styled-components";


export const GrapthStyled = styled.svg`
  width: 102px;

  & circle {
    fill: none;
    stroke-width: 8; 
    cursor: pointer;
    transition: all 1.5s; 
  }
  
  & circle:nth-of-type(1) {
    stroke: #FFFF;
  }

  /* & circle:nth-of-type(2) {
    stroke: #8BD7DC;
    stroke-dasharray: 33 120;
  }
  
  & circle:nth-of-type(3) {
    stroke: #F2D877;
    stroke-dasharray: 0 33 33 100;
  }

  & circle:nth-of-type(4) {
    stroke: #D47ED6;
    stroke-dasharray: 0 66 0 100;
  } */
  
`