import React, { memo } from "react";

import { IconUI } from "shared/ui/Icon";
import { LinkHeaders, LinkMenu } from "../config";
import { WalletConnectBody, WalletConnectBtn } from "features/walletConnect";
import { AppRoutes, landingRoute } from "shared/config/routeConfig";
import { Button, ButtonTheme } from "shared/ui/Button/Button";

import {
  Container,
  Back,
  BackImg,
  MenuLinks,
  Links,
  BodyRight,
  BackText,
  BackButtonContent,
  MenuModileBody,
  IconModileBody,
  Balances,
  BackBody,
} from "./styled";

import logo from "shared/assets/images/logo.svg";
import smallLogo from "shared/assets/images/smallLogo.svg"
import { BalancesUser } from "entities/user";
import { Menu, MenuBtn } from "shared/ui/MenuMobile";
import { useChange } from "shared/lib/useChange";
import { useRouteHeader } from "../model";
import { RaffleHeader } from "entities/Raffle";

export const Header = memo(() => {
  const [isMenu, setMenu] = useChange();
  const { isRaffle, isLinkActive } = useRouteHeader();

  return (
    <>
      <Container isActive={isRaffle}>
        <BackBody isReward={isRaffle}>
          <Back to={landingRoute}>
            <Button theme={ButtonTheme.OCEAN_BLUE}>
              <BackButtonContent>
                <BackImg>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 16L0 8L8 0L9.05 1.05L2.85 7.25H16V8.75H2.85L9.05 14.95L8 16Z"
                      fill="#6EB9D4"
                    />
                  </svg>
                </BackImg>
                <BackText>Back to main</BackText>
              </BackButtonContent>
            </Button>
          </Back>
        </BackBody>

        {!isRaffle && <RaffleHeader />}

        <MenuLinks>
          {LinkHeaders.map((link) => (
            <Links
              key={link.title}
              to={link.route}
              active={isLinkActive(link.type)}
            >
              {link.title}
            </Links>
          ))}
        </MenuLinks>

        <IconModileBody to={landingRoute}>
          <IconUI src={smallLogo} />
        </IconModileBody>

        <BodyRight>
          <Balances>
            <BalancesUser />
          </Balances>

          <WalletConnectBody>
            <WalletConnectBtn />
          </WalletConnectBody>
        </BodyRight>

        <MenuModileBody>
          <MenuBtn
            isActive={isMenu}
            isMenu={false}
            setClick={setMenu}
          />
        </MenuModileBody>
      </Container>

      <Menu
        isActive={isMenu}
        links={LinkMenu}
        setClick={setMenu}
        where="game"
      />
    </>
  );
});