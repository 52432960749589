
import styled from 'styled-components';

interface modapProps {
    isModal:boolean;
}

export const CirleCard = styled.div`
    position: absolute;
    width: 329px;
    height: 283px;
	opacity: 0.2;
    background: rgba(212, 126, 214, 0.16);
    filter: blur(100px);
    border-radius: 50%;
`

export const TopCard = styled(CirleCard)`
    top: -141px;
    left: -144px;
`

export const BottomCard = styled(CirleCard)`
    bottom: 6px;
    right: -83px;
`


export const MiniText = styled.p`
    font-weight: 400;
    font-size: var(--font-size-small);
    color: #7B859F;
`

export const HeadCard = styled.div`
    position:relative;
    z-index: 2;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

  padding: 0 16px;

  @media(max-width: 1130px) {
    width: 85.365853659%;
    padding: 0;
  }
`


export const IdRound = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-medium);
    color: #E4EBFA;
    margin-left: 8px;
`

export const HeaderRightInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

  @media(max-width: 1130px) {
    flex-direction: row;
    align-items: center;
    gap:8px;
  }
`

export const TypeCard = styled.p`
  font-weight: 600;
  font-size: var(--font-size-large);


  @media(max-width: 1130px) {
    font-size: var(--font-size-small);
  }
`

export const GameTicketMin = styled.p`
    font-weight: 400;
    font-size: var(--font-size-x-small);
    text-align: right;
    color: #E4EBFA;
    margin-top: 4px;

  @media(max-width: 1130px) {
    margin-top: 0;
  }
`

export const MainBody = styled.div`
    position:relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items:center;
    margin-top: 18px;
  @media(max-width: 1130px) {
    width: 85.365853659%;
    margin-top: 0;
  }
`

interface ContainerContentProps extends modapProps {
    mobileOrder:string
}

export const ContainerContent = styled.div
    .attrs((props:ContainerContentProps) => props)`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media(max-width: 1130px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    
    order:${(props) => props.isModal ? '2' : props.mobileOrder};
  }
`

export const EndTime = styled.h2`
    font-weight: 600;
    font-size: var(--font-size-x-large);
    line-height: 32px;
    text-align: center;

  @media(max-width: 1130px) {
    order: 2;
    color:var(--font-color-yellow100);
    font-size: var(--font-size-small);
    line-height: initial;
  }
`


export const EndTimeTextBottom = styled(MiniText)`
    margin-top: 7px;

  @media(max-width: 1130px) {
    order: 1;
    margin-top: 0;
    
    color: #7B859F;
  }
`

export const PossibleReward = styled(MiniText)
    .attrs((props:modapProps) => props)`
    margin-top: 20px;

    font-weight: 400;
    font-size: var(--font-size-medium);
    line-height: 24px;
  
  @media(max-width: 1130px) {
    margin-top: 16px;
    order:3;
  }

  @media(max-width: 400px) {
    font-size: var(--font-size-x-small);
    line-height:initial;
  }
`

export const Bids = styled.p`
    font-weight: 600;
    font-size: var(--font-size-large);
    line-height: 24px;
    color: #E4EBFA;
    margin-top: 16px;
  
  @media(max-width: 1130px) {
    order: 1;
    font-size: var(--font-size-small);
    margin-top: 0;
    line-height:initial;
  }
`

export const BidsText = styled(MiniText)`
    margin-top: 4px;
  
  @media(max-width: 1130px) {
    margin-top: 0;
  }
`

export const ButtonConfrim = styled.div`
  margin-top:16px;
`

export const ButtonBid = styled.div`
  width: 140px;
  height: 40px;
  margin-top:16px;
`


interface ContainerCardProps extends modapProps {
    color: string
}

export const ContainerCard = styled.div
    .attrs((props: ContainerCardProps) => props)`
    position: relative;
    z-index: 3;
    
    width: ${props => props.isModal ? '91.11' : '100'}%;
    min-height: 210px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px 0px 24px 0px;

    background: #202840;
    border: 1px solid ${props => props.color};
    backdrop-filter: blur(8.5px);
    border-radius: 16px;
    overflow: hidden;

    & ${PossibleReward}, & ${TypeCard} {
        color: ${props => props.color};
    }

    & ${CirleCard}, & ${ButtonConfrim} {
        background: ${props => props.color};
    }

  @media(max-width: 1330px) {
    padding-top: ${props => props.isModal && '41px'} !important;
  }
  
  @media(max-width: 365px) {
    padding: 16px 10px 24px 10px;
  }
  
`
export const BidMadeWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const BidMade = styled.div`
  padding: 4px 8px;

  position: absolute;
  top: 0;
  left: 0;

  
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  background: linear-gradient(88.62deg, #7250DD 0%, #528BC0 100%);

  @media(max-width: 1130px) {
    transform: rotate(45deg);
    width: 12px;
    height: 80px;
    padding: 0;
    top: -56px;
    left: 8px;
  }

  @media(max-width: 370px) {
    height: 70px;
    top: -51px;
    left: -2px;
  }
`

export const BidMadeText = styled.span`

  font-size: var(--font-size-small);
  line-height: 14px;
  
  @media(max-width: 1130px) {
    display: none;
  }
`
