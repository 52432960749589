
// MOCK
import {changeFiltersGame} from "entities/Games/model";

export const tabs = [
    {
        tab:{
            text:'Classic',
            active: false,
            type:''
        },
        onChangeFilter: () => changeFiltersGame(0),
    },

    {
        tab:{
            text:'Premium',
            active: false,
            type:''
        },
        onChangeFilter: () => changeFiltersGame(1),
    },

    {
        tab:{
            text:'Ultimate',
            active: false,
            type:''
        },
        onChangeFilter: () => changeFiltersGame(2),
    },
]