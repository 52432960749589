
import { memo } from "react";
import {isLoading, SkeletonLoading, SkeletonWrapper} from "./skeleton.styled";

interface SkeletonProps extends isLoading{
    children:React.ReactNode,
}

export const Skeleton = memo(({children, isLoading, cssBody}:SkeletonProps) => {

    return(
        <SkeletonWrapper isLoading={isLoading} cssBody={cssBody}>
            {isLoading && <SkeletonLoading />}

            {!isLoading &&
                children
            }
        </SkeletonWrapper>
    )
})