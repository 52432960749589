import { css } from "styled-components";

export const SocLinksImg = css`
  cursor: pointer;
  transition: all 0.3s ease-in;

  & svg,
  & svg path {
    width: 100%;
    height: 100%;
  }

  &:hover svg path {
    transition: all 0.3s ease-in;
    fill: var(--font-color-white10);
  }
`;