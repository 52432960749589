import { memo } from "react";

import {
  CardInfo,
  CardInfoActive,
  CardInfoLast,
  CardsContainer,
} from "./styled";

import { CardGame } from "./ActiveGame/CardGame";
import { LastGameCard } from "./LastGame";
import { CardsProps, useGetCurrentGames } from "entities/Games";
import { useGetLast } from "../../model";
import { Skeleton } from "shared/ui/Skeleton";

export const Cards = memo(() => {
  const cards:CardsProps[] = useGetCurrentGames();
  const lastGames = useGetLast();

  return (
    <CardsContainer>
      {[0, 1, 2].map((card) => {
        return (
          <CardInfo key={card}>
            <Skeleton
              isLoading={!Boolean(cards[card])}
              cssBody={CardInfoActive}
            >
              <CardGame {...cards[card]} idCard={card} />
            </Skeleton>
            <Skeleton
              isLoading={!Boolean(lastGames[card])}
              cssBody={CardInfoLast}
            >
              <LastGameCard {...lastGames[card]} />
            </Skeleton>
          </CardInfo>
        );
      })}
    </CardsContainer>
  );
});
