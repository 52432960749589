import styled, {css} from "styled-components";
import {Link} from "atomic-router-react";


export const RaffleEndTime = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #E4EBFA;
    margin-left: auto;
`
export const RaffleText = styled.p`
  margin-left: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #F2D877;
`

export const SkeletonCss = String(css`
  height: 100%;
  max-width: 214px;

  @media(max-width: 1000px) {
    display: none;
  }
`)

export const Raffle = styled(Link)`
  position: relative;

  ${SkeletonCss};

  display: flex;
  align-items: center;

  background: #2A3554;
  border: 1px solid #F2D877;
  border-radius: 12px;

  padding-right: 12px;

  transition: all .3s ease-in;

  &:hover {
    background: #F2D877;
  }
  &:hover ${RaffleText} {
    color: #131625;
  }
  &:hover ${RaffleEndTime} {
    color: #131625;
  }
`
export const RaffleImg = styled.div`
    width: 66px;
    height: 64px;
`