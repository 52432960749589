import {gql} from "@apollo/client";

export const BALANCE_TICKETS = gql(`
    subscription balanceTickets($address: String) {
        root(id:0) {
            raffleCurrentRound {
                ticketsSum
                players(where: {player: $address}) {
                    tickets
                }
            }
        }
    }
`)