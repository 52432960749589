

import {memo, ReactNode, useEffect} from "react";

import {useAccount, useBlockNumber} from "wagmi";
import {useQuery, useSubscription} from "@apollo/client";
import {formatEther} from "viem";

import {modalStore} from "shared/lib/modal";
import {RaffleModal} from "entities/Raffle";
import {TIME, IS_WINNER} from "./model";
import {formattedIntegers} from "shared/lib/formatted";
import {setTimeCurrent} from "shared/lib/timeCurrent";
import {notificationStore, NotificationType} from "shared/lib/notification";
import {NotificationText} from "shared/ui/Notification";
import {GET_RAFFLE_IS_MODAL, SET_MODAL_IS_RAFFLE} from "processes/GraphQueryInit/config";

export const GraphQueryInit = memo(({children}:{children:ReactNode}) => {
    const {address} = useAccount();
    const {data:blockNumber} = useBlockNumber()

    const {data} = useSubscription(IS_WINNER, {
        variables:{address}
    });

    const {data:time} = useQuery(TIME);

    useEffect(() => {
        if(!data?.root) return;

        const isWinners = data?.root.raffleCurrentRound.winner;

        const idRaffle = +data?.root.raffleCurrentRound.id;
        const isSeeRaffleModal = Array.isArray(GET_RAFFLE_IS_MODAL) && GET_RAFFLE_IS_MODAL.find(element => element[idRaffle])?.[idRaffle];

        if(isWinners.length !== 0 && !isWinners?.[0].isClaimed && !isSeeRaffleModal) {
            const [isWinner] = isWinners;
            const winnerData = {
                amount:formattedIntegers(formatEther(isWinners[0].amount)),
                place:isWinner.isJackpot ? '1' : '2-26'
            }

            modalStore.createModal({
                dataProps:winnerData,
                ModalElement:RaffleModal,
            });

            SET_MODAL_IS_RAFFLE(idRaffle);
        }

    }, [data])

    useEffect(() => {
        if(!time?._meta) return;

        const Time = time?._meta.block.timestamp * 1000;
        setTimeCurrent(Time)
        const blockGraph = time._meta.block.number;

        if(blockGraph + 5 <= Number(blockNumber)) {

            notificationStore.createNotification({
                type:NotificationType.INFO,
                text:[
                    <NotificationText>Wait. The information is synchronized with the blockchain.</NotificationText>,
                    <NotificationText>Displayed data may not be correct.</NotificationText>,
                    <NotificationText color="var(--font-color-blue75)" >Current block is {blockNumber}</NotificationText>,
                    <NotificationText color="var(--font-color-blue75)" >Sync block is on {blockGraph}</NotificationText>,
                ]
            })
        }

    }, [time, blockNumber])

    return <>{children}</>;
})