import styled from "styled-components";

export const TabBtn = styled.div`
  width: auto;
  height: 40px;@media (max-width: 1000px) {
      height: 30px;
    };
`

export const TabText = styled.div`
  padding: 0 10px;
  
  @media (max-width: 1000px) {
    padding: 0 6px;
  };

  @media (max-width: 600px) {
    padding: 0 4px;
    font-size: 10px;
  };
`