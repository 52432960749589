import { Item, ItemText } from "../styled";

import { textProps } from "shared/config/sharedType";

interface TextDefaultProps extends textProps{
    color?: string;
}

export const TextDefault = ({text, color}: TextDefaultProps) => (
    <Item>
        <ItemText
            color={color}
        >
            {text}
        </ItemText>
    </Item>
)