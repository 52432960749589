import { Link } from "atomic-router-react";
import { WalletConnectBody } from "features/walletConnect";
import { isActive } from "shared/config/sharedType";
import styled from "styled-components";

export const BackImg = styled.div`
  width: 16px;
  height: 16px;
  z-index: 1000;
`;

export const BackBody = styled.div.attrs(
  (props: { isReward: boolean }) => props
)`
  width: ${(props) => props.isReward && "327px"};
  height: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Back = styled(Link)`
  height: 100%;
  width: 166px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 600;
  font-size: 16px;

  color: #6eb9d4;

  &:hover ${BackImg} svg path {
    fill: #131625;
    transition: all 0.3s ease-in;
  }
`;

export const BodyRight = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 1250px) {
    justify-content: space-between;
  }

  @media (max-width: 1000px) {
    width: auto;
  }
`;

export const Container = styled.div.attrs((props: isActive) => props)`
  height: 40px;
  margin-top: 18px;

  ${(props) =>
    props.isActive
      ? `
			display: flex;
			justify-content: space-between;
    `
      : `
    		display: grid;
    		grid-template: 100% / 19.316239316% 25.128205128% 17.777777778% 37.777777778%;
    		align-items: center;
    		
		`}

  & ${WalletConnectBody} {
    margin-left: ${(props) => props.isActive && "37px"};
    @media (max-width: 1000px) {
      margin-left: 0;
    }
  }

  @media (max-width: 1250px) {
    width: ${(props) => !props.isActive && "100%"};
  }

  @media (max-width: 1130px) {
    ${(props) =>
      !props.isActive &&
      `
        grid-template: 100% / 20% 22.128205128% 16.777777778% 34.777777778%;
    `}
    justify-content: space-between;
  }
  @media (max-width: 1050px) {
    ${(props) =>
      !props.isActive &&
      `
        grid-template: 100% / 20% 22.128205128% 14.777777778% 37.777777778%;
    `}
  }

  @media (max-width: 1000px) {
    display: flex;
    justify-content: space-between;
  }
  z-index: 10;
`;

export const BackButtonContent = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`;

export const BackText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  @media (max-width: 1130px) {
    font-size: 14px;
  }
`;

export const MenuLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 52px;

  @media (max-width: 1250px) {
    gap: 30px;
    justify-content: center;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Links = styled(Link).attrs((props: { active: boolean }) => props)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.active ? "#6EB9D4" : "#E4EBFA")};

  transition: all 0.3s ease-in;

  &:hover {
    color: #6eb9d4;
  }
`;

export const Balances = styled.div`
  width: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const IconModileBody = styled(Link)`
  width: 36px;
  height: 36px;

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const MenuModileBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1000px) {
    display: none;
  }
`;