import styled from "styled-components";
import {isActive} from "shared/config/sharedType";

export const BodyPagination = styled.div`
  width: 188px;
  height: 28px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:4px;
  
  margin: 0 auto;
  margin-top: 16px;
`

export const PaginationBtn = styled.button`
  width: 28px;
  height: 100%;
  
  cursor: pointer;
  border: none;
  background: none;
`

export const PaginationBtnPage = styled.button
    .attrs((props: isActive) => props)`
  width: 28px;
  height: 100%;
  
  font-weight: 600;
  font-size: var(--font-size-small);
  line-height: 14px;

  color: ${props => props.isActive ? "var(--font-color-blue50)" : "var(--font-color-blue75)"} ;
  
`