import {CardType} from "shared/config/gamesTypes";


export enum REWARD_PRECENT {
	CLASSIC = 20,
	PREMIUM = 15,
	ULTIMATE = 10,
	JACKPOT = 5,
}

export enum CardTickets {
	CLASSIC = 1,
	PREMIUM = 2,
	ULTIMATE = 10,
}

export enum CardTypeBG {
	Classic = '#D47ED6',
	Premium = '#F2D877',
	Ultimate = '#8BD7DC',
	Default = "#202840"
}

export interface CardsPrevious {
	id:number,
	gameId:number,
	date:number,
	participated:number,
	winBid:'white' | 'red', // MOCK
	rewardLevel:string,
}

export interface CardsProps {
	id: number;
	idCard?: number;
	deadline: number;
	tickets: number;
	title: CardType;
	current: {
		id: number,
		participiants:number,
		userValueDecrypted: number,
		valueDecrypted: number,
	};
	madeIsBid:boolean,
	bidsMax:number,
	isModal?:boolean,
}

export interface paramsForBet {
	gameId: number,
	roundId: number,
	address: string,
	roundPk: string,
	voteData: string,
	signature: string,
}

export interface RoundInfoTypes {
	intRoundId:number,
	participated:number,
	rewardLevel:number,
	reward:number,
	winnerBid:string | undefined,
	date:string,
}

