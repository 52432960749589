import { TextDefault, } from './components/text';
import { Balance } from './components/balance';
import { Bet } from './components/bet';
import { ParticipatedText } from './components/participated';
import { ClaimBtn } from 'features/ClaimReward';

import { textProps, transformProps } from 'shared/config/sharedType';
import { Links } from "shared/ui/Table/item/components/links";

export const transformItemTablet = (type: transformProps, text: textProps) => {

	switch (type) {
		case transformProps.TEXT:
			return <TextDefault {...text} />;

		case transformProps.BALANCE:
			return <Balance {...text} />;

		case transformProps.TEXT_LINK_CLASSIC:
			return <Links {...text} idGame={0} />;

		case transformProps.TEXT_LINK_PREMIUM:
			return <Links {...text} idGame={1} />;

		case transformProps.TEXT_LINK_ULTIMATE:
			return <Links {...text} idGame={2} />;

		case transformProps.TEXT_CLASSIC:
			return <TextDefault {...text} color="var(--font-color-pink100)" />;

		case transformProps.TEXT_GOLD:
			return <TextDefault {...text} color="#F2D877" />;

		case transformProps.TEXT_ULTIMATE:
			return <TextDefault {...text} color="var(--font-color-ultimate100)" />;

		case transformProps.BET_RED:
			return <Bet {...text} bet={transformProps.BET_RED} />;

		case transformProps.BET_WHITE:
			return <Bet {...text} bet={transformProps.BET_WHITE} />;

		case transformProps.STATUS_WIN:
			return <TextDefault {...text} color="#82CF75" />;

		case transformProps.STATUS_LOOSE:
			return <TextDefault {...text} color="var(--font-color-blue75)" />;

		case transformProps.PARTICIPATED:
			return <ParticipatedText />
	}
};
