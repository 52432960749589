import styled from "styled-components";

export const Body = styled.main`
    max-width: 97.777777778%;
    margin: 0 auto;
	position: relative;
	z-index: 1;
`

export const NavBarContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
`

export const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    

    width: 83.096590909%;
    margin: 0 auto;

	position: relative;
`