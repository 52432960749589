import {useStore} from "effector-react";

import {router} from "shared/config/routeConfig";
import {LinkHeaderType} from "../config";

export const useRouteHeader = () => {
    const path = useStore(router.$path);
    const pageName = path.split('/')[1];
    const isRaffle = pageName === 'raffle' || pageName === 'reward';

    const isLinkActive = (e: LinkHeaderType) => {
        const route = path.split('/');
        return route.includes(e);
    };

    return {
        isRaffle,
        isLinkActive
    }
}