
const Completionist = () => <span>Finalizing!</span>;
const CompletionistGames = () => <span>Finalizing!</span>;

interface TimerRender {
    days:number,
    hours:number,
    minutes:number,
    seconds:number,
    completed:boolean
}

const Timer = ({ hours, minutes, seconds }:TimerRender) => (
    <span>
        {hours < 10 ? `0${hours}` : hours}
        :
        {minutes < 10 ? `0${minutes}` : minutes}
        :
        {seconds < 10 ? `0${seconds}` : seconds}
    </span>
);

const TimerRaffle = ({ days, hours, minutes, seconds }:TimerRender) => (
    <span>
        {days < 10 ? `0${days}` : days}
        :
        {hours < 10 ? `0${hours}` : hours}
        :
        {minutes < 10 ? `0${minutes}` : minutes}
        :
        {seconds < 10 ? `0${seconds}` : seconds}
    </span>
);

export const rendererTimer = (props:TimerRender) => {
    if (props.completed) {
        return <Completionist />;
    } else {
        return (
            <Timer {...props} />
        );
    }
};

export const rendererTimerRaffle = (props:TimerRender) => {
    if (props.completed) {
        return <Completionist />;
    } else {
        return (
            <TimerRaffle {...props} />
        );
    }
};

export const rendererTimerGames = (props:TimerRender) => {
    if (props.completed) {
        return <CompletionistGames />;
    } else {
        return (
            <Timer {...props} />
        );
    }
};


