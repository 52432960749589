import { isActive } from "shared/config/sharedType";
import styled, {css} from "styled-components";

export const WalletConnectBody = styled.div`
    width: 100%;
    height: 100%;
`

export const ConatinerHead = styled.div`
    width: 85%;

    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const IsChoose = styled.div`
    position: absolute;
    bottom: -4px;

    width:100%;
    height: 1px;
`

export const VarintConnect = styled.div
    .attrs((props: isActive) => props)`

    cursor: pointer;

    & span {
        color:${props => props.isActive ? 'var(--font-color-blue50)' : 'var(--font-color-white100)'};
        font-weight: 600;
        font-size: var(--font-size-medium);
        line-height: 20px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    position: relative;

    & ${IsChoose} {
        background-color: ${props => props.isActive ? 'var(--font-color-blue50)' : 'none'};
    }
`

export const TextDescription = styled.p`
    font-weight: 400;
    font-size: var(--font-size-small);
    line-height: 14px;
    text-align: center;
    color: var(--font-color-blue75);

    margin-top: 28px;
`

export const QrCodeCopySS = String(css`
  width: 94px;
  height: 14px;
  margin: 0 auto;

  margin-top: 24px;
`)

export const CopyText = styled.div`
  font-weight: 400;
  font-size: var(--font-size-small);
  text-align: center;
  color: var(--font-color-blue50);
  cursor: pointer;

  &:hover {
    color:var(--font-color-white100);
    transition: color 0.5s ease;
  }
`


