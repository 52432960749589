import styled, {keyframes} from "styled-components";

export interface isLoading {
    isLoading?:boolean,
    cssBody?:string,
}

export const SkeletonWrapper = styled.div
    .attrs((props:isLoading) => props)`
  
  border-radius: 16px;
  background: ${props => props.isLoading && '#394158'} ;
  position: relative;
  overflow:${props => props.isLoading && 'hidden'};
  
  ${props => props.cssBody};
`

const animationLoading = keyframes`
  0%{
    left: -100%;
  }

  100%{
    left: 200%;
  }
`

export const SkeletonLoading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(57, 64, 88, 0) 0%, #5B6789 33.33%, #5B6789 66.67%, rgba(57, 65, 88, 0) 100%);;
  animation: 2s ${animationLoading} infinite ease-in;
`

