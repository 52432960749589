import {
  AppRoutes,
  gameRoute,
  referralRoute,
  rewardRoute,
  statisticsRoute,
} from "shared/config/routeConfig";

const game = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 24C1.44444 24 0.972222 23.8056 0.583333 23.4167C0.194444 23.0278 0 22.5556 0 22V8C0 5.77778 0.777778 3.88889 2.33333 2.33333C3.88889 0.777778 5.77778 0 8 0H16C18.2222 0 20.1111 0.777778 21.6667 2.33333C23.2222 3.88889 24 5.77778 24 8V22C24 22.5556 23.8056 23.0278 23.4167 23.4167C23.0278 23.8056 22.5556 24 22 24H2ZM2 22H22V8C22 6.33333 21.4167 4.91667 20.25 3.75C19.0833 2.58333 17.6667 2 16 2H8C6.33333 2 4.91667 2.58333 3.75 3.75C2.58333 4.91667 2 6.33333 2 8V22ZM8 11.6667C7.35556 11.6667 6.80556 11.4389 6.35 10.9833C5.89444 10.5278 5.66667 9.97778 5.66667 9.33333C5.66667 8.68889 5.89444 8.13889 6.35 7.68333C6.80556 7.22778 7.35556 7 8 7C8.64444 7 9.19444 7.22778 9.65 7.68333C10.1056 8.13889 10.3333 8.68889 10.3333 9.33333C10.3333 9.97778 10.1056 10.5278 9.65 10.9833C9.19444 11.4389 8.64444 11.6667 8 11.6667ZM16 11.6667C15.3556 11.6667 14.8056 11.4389 14.35 10.9833C13.8944 10.5278 13.6667 9.97778 13.6667 9.33333C13.6667 8.68889 13.8944 8.13889 14.35 7.68333C14.8056 7.22778 15.3556 7 16 7C16.6444 7 17.1944 7.22778 17.65 7.68333C18.1056 8.13889 18.3333 8.68889 18.3333 9.33333C18.3333 9.97778 18.1056 10.5278 17.65 10.9833C17.1944 11.4389 16.6444 11.6667 16 11.6667ZM5.66667 22V18.3333C5.66667 17.7778 5.86111 17.3056 6.25 16.9167C6.63889 16.5278 7.11111 16.3333 7.66667 16.3333H16.3333C16.8889 16.3333 17.3611 16.5278 17.75 16.9167C18.1389 17.3056 18.3333 17.7778 18.3333 18.3333V22H16.3333V18.3333H13V22H11V18.3333H7.66667V22H5.66667Z"
      fill="#6EB9D4"
    />
  </svg>
);

const referal = (
  <svg
    width="24"
    height="12"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1 12H6C4.34 12 2.925 11.415 1.755 10.245C0.585 9.075 0 7.66 0 6C0 4.34 0.585 2.925 1.755 1.755C2.925 0.585 4.34 0 6 0H11.1V1.8H6C4.84 1.8 3.85 2.21 3.03 3.03C2.21 3.85 1.8 4.84 1.8 6C1.8 7.16 2.21 8.15 3.03 8.97C3.85 9.79 4.84 10.2 6 10.2H11.1V12ZM7.35 6.9V5.1H16.65V6.9H7.35ZM12.9 12V10.2H18C19.16 10.2 20.15 9.79 20.97 8.97C21.79 8.15 22.2 7.16 22.2 6C22.2 4.84 21.79 3.85 20.97 3.03C20.15 2.21 19.16 1.8 18 1.8H12.9V0H18C19.66 0 21.075 0.585 22.245 1.755C23.415 2.925 24 4.34 24 6C24 7.66 23.415 9.075 22.245 10.245C21.075 11.415 19.66 12 18 12H12.9Z"
      fill="#7B859F"
    />
  </svg>
);

const reward = (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1845 13.0378L21.7459 9.17862L24 9.36115L18.9878 13.5854L20.4994 20L18.537 18.8266L17.1845 13.0378ZM14.7182 5.50196L13.2066 2.00782L14.0552 0L16.495 5.65841L14.7182 5.50196ZM6.4442 16.558L10.6077 14.1069L14.7713 16.5841L13.6575 11.9426L17.3171 8.81356L12.4906 8.39635L10.6077 4.01565L8.72486 8.37027L3.89834 8.78748L7.55801 11.9166L6.4442 16.558ZM4.05746 19.8175L5.78122 12.4902L0 7.56193L7.63757 6.91004L10.6077 0L13.5779 6.91004L21.2155 7.56193L15.4343 12.4902L17.158 19.8175L10.6077 15.9322L4.05746 19.8175Z"
      fill="#7B859F"
    />
  </svg>
);

const statistic = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 24V21.4667L2 19.4667V24H0ZM5.5 24V16.1333L7.5 14.1333V24H5.5ZM11 24V14.1333L13 16.1667V24H11ZM16.5 24V16.1667L18.5 14.1667V24H16.5ZM22 24V10.8L24 8.8V24H22ZM0 16.1333V13.3L9.33333 4.03333L14.6667 9.36667L24 0V2.83333L14.6667 12.2L9.33333 6.86667L0 16.1333Z"
      fill="#7B859F"
    />
  </svg>
);

export const LinksMenu = [
  {
    title: "Game",
    path: gameRoute,
    link:AppRoutes.GAME,
    image: game,
  },

  {
    title: "Reward",
    path: rewardRoute,
    link:AppRoutes.REWARD,
    image: reward,
  },

  {
    title: "Statistic",
    path: statisticsRoute,
    link:AppRoutes.STATISTICS,
    image: statistic,
  },

  {
    title: "Referral",
    path: referralRoute,
    link:AppRoutes.REFERRAL,
    image: referal,
  },
];
