

import styled from 'styled-components';
import { gridTableCSS } from '../table.styled';

export const Container = styled.div
	.attrs((props: { css: string }) => props)`
    
    ${gridTableCSS}

    padding-bottom: 8px;

    border-bottom:1px solid #7B859F;

    ${props => props.css};
    overflow-x:hidden;

		@media (max-width: 1000px) {
			padding-bottom: 4px;
		};
`

export const HeaderTitle = styled.div`
    height:100%;
    width:100%;
    font-weight: 400;
    font-size: var(--font-size-small);
    line-height: 14px;
    color: var(--font-color-blue75);

		@media (max-width: 1000px) {
			font-size: 10px;
			line-height: 10px;
		};

		@media (max-width: 600px) {
			font-size: 8px;
			line-height: 10px;
		};
`