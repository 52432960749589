import { HTMLAttributes, ReactNode } from 'react';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './Card.module.css';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    children?: ReactNode;
    theme?: CardTheme;
    doubleFogCircle?: boolean;
    overflow?: CardOverflow;
    width?: string;
    notCircle?: boolean;
		borderRadius?: string;
}

export enum CardTheme {
    DEFAULT = 'default',
    CLASSIC = 'classic',
    PREMIUM = 'premium',
    ULTIMATE = 'ultimate',
    BLUE = 'blue',
    OCEAN = 'ocean',
    OUTLINED_BLUE = 'outlinedBlue',
    YELLOW = 'yellow',
    BLACK = 'black',
    RAFFLE = 'raffle',
}

export enum CardOverflow {
    HIDDEN = 'overflowHidden',
    VISIBLE = 'overflowVisible',
}
export enum CardBorderRadius {
	DEFAULT = "middleBorderRadius",
	SMALL = "smallBorderRadius"
}

export const Card = (props: CardProps) => {
    const {
        className,
        children,
        doubleFogCircle = false,
        overflow = CardOverflow.HIDDEN,
        theme = CardTheme.DEFAULT,
        width = '100%',
        notCircle = false,
				borderRadius = CardBorderRadius.DEFAULT,
        ...otherProps
    } = props;
    return (
        <div
            className={classNames(cls.Card, {}, [className, cls[theme], cls[overflow], cls[borderRadius]])}
            style={{ width: width }}
            {...otherProps}>
            {doubleFogCircle && <div className={classNames(cls.circle, {}, [cls.topCircle])} />}
            {!notCircle && <div className={classNames(cls.circle, {}, [cls.bottomCircle])} />}
            {children}
        </div>
    );
};
