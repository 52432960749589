import { memo } from "react";

import { Body, BodyContent, NavBarContent } from "app/styles";
import { MainNavbar } from "widgets/MainNavbar";
import { Header } from "widgets/Header";
import { Footer } from "widgets/Footer";
import { Blicks } from "shared/ui/WrapperBlicks";
import { SetChainDefault } from "processes/setChainDefault";
import { GraphQueryInit } from "processes/GraphQueryInit";

export const AppUI = memo(({ children }: { children: React.ReactNode }) => (
  <SetChainDefault>
    <GraphQueryInit>
      <Blicks>
        <Body>
          <NavBarContent>
            <MainNavbar />

            <BodyContent>
              <Header />
              {children}
              <Footer />
            </BodyContent>
          </NavBarContent>
        </Body>
      </Blicks>
    </GraphQueryInit>
  </SetChainDefault>
));