import styled from "styled-components";
import { ButtonConfrim } from "../../CardGame.styled"
import {Participate} from "entities/Games/ui/Games/ActiveGame/isOpen/Participate/index";

export const ParticipateBtn = styled.div`
  height: 40px;
`

export const ParticipateBtnText = styled.span`
  padding: 10px;
`

export const ParticipateWrapper = styled.div`
  margin-top: 12px;

  @media(max-width: 1130px) {
    margin-top: 16px;
    order:4;
  }
`

export const UpToBids = styled.div`
    margin-top: 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #E4EBFA;
    text-align: center;

  @media(max-width: 1130px) {
    margin-top: 16px;
  }
`