import {CardType, ColorType} from "shared/config/gamesTypes";
import {chainId, rpc} from "shared/config/blockChain";
import {ButtonTheme} from "shared/ui/Button/Button";
import {transformProps} from "shared/config/sharedType";

export const transformTypeCard = (cardType: CardType) => {
    switch (cardType) {
        case CardType.CLASSIC:
            return {
                theme: ButtonTheme.WHITE_CLASSIC,
                price: chainId == 56 ? '0.1' : '0.0001',
                possibleReward: '50',
            };

        case CardType.PREMIUM:
            return {
                theme: ButtonTheme.WHITE_PREMIUM,
                price: chainId == 56 ? '0.25' : '0.00025',
                possibleReward: '60',
            };

        default:
            return {
                theme: ButtonTheme.WHITE_ULTIMATE,
                price: chainId == 56 ? '1' : '0.001',
                possibleReward: '70',
            };
    }
}

export const transformIntToName = (cardInt: number | string) => {
    switch (true) {
        case cardInt === '0' || cardInt === 0:
            return {
                content: CardType.CLASSIC,
                title: 'CLASSIC',
            };

        case cardInt === '1' || cardInt === 1:
            return {
                content: CardType.PREMIUM,
                title: 'PREMIUM',
            };

        default:
            return {
                content: CardType.ULTIMATE,
                title: 'ULTIMATE',
            };
    }
}

export const transformNameToInt = (cardName: string) => {
    switch (true) {
        case cardName === "CLASSIC":
            return 0;

        case cardName === "PREMIUM":
            return 1;

        default:
            return 2;
    }
}

export const transformColor = (colorActive: ColorType) => (
    colorActive === ColorType.RED ? "red" : "white"
)

export const transformColorTable = (cardType: CardType) => {
    switch (cardType) {
        case CardType.CLASSIC:
            return transformProps.TEXT_CLASSIC;

        case CardType.PREMIUM:
            return transformProps.TEXT_GOLD;

        default:
            return transformProps.TEXT_ULTIMATE;
    }
}
