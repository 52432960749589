import {useAccount} from "wagmi";
import {useStore} from "effector-react";

import {AppRoutes, landingRoute, loginRoute, router} from "shared/config/routeConfig";
import {modalStore} from "shared/lib/modal";
import {WalletConnect} from "features/walletConnect";
import {LazyLayout} from "shared/ui/Loading";

export const protectedPages = (component: () => React.ReactNode) => (() => {
    const {address} = useAccount();
    const route = useStore(router.$path);
    const {LANDING, LOGIN} = AppRoutes;

    if(route === LANDING) {
        landingRoute.open();
    }

    if(!address && route !== LANDING && route !== LOGIN) {
        loginRoute.open();
        //@ts-ignore
        modalStore.createModal({
            dataProps: {
                connectWallet:true,
            },
            ModalElement: WalletConnect,
        });
    }

    return <LazyLayout>{component()}</LazyLayout>;
})