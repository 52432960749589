import styled from "styled-components"

export const RafflePoolDestributed = styled.div`
	width: 31%;

	@media (max-width: 1000px) {
		width: 100%;

		order: 1;
	};
`
export const RafflePoolDestributedInfo = styled.div`
	padding: 16px 24px;
`
export const RafflePoolDestributedTitle = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #E4EBFA;
`
export const RafflePoolDestributedPlaces = styled.div`
	margin-top: 16px;

	display: flex;
	flex-direction: column;
	gap: 8px;
	

	font-style: normal;
`
export const RafflePoolDestributedFirstPlace = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #F2D877;

	@media (max-width: 600px) {
		font-size: 12px;
		line-height: 14px;
	};
`
export const RafflePoolDestributedFirstPlacePercent = styled.div`
	font-style: normal;
	color: #E4EBFA;

	@media (max-width: 600px) {
		font-size: 12px;
		line-height: 14px;
	};
`
export const RafflePoolDestributedOtherPlaces = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	font-style: normal;

	@media (max-width: 600px) {
		font-size: 12px;
		line-height: 14px;
	};
`
export const RafflePoolDestributedOtherPlacesPercent = styled.div`
	font-style: normal;

	@media (max-width: 600px) {
		font-size: 12px;
		line-height: 14px;
	};
`
export const RafflePoolDestributedNotification = styled.div`
	margin-top: 12px;

	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 14px;
	color: #7B859F;
`