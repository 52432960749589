
import {useAccount, useContract, useSigner} from "wagmi";
import {useQuery} from "@apollo/client";

import {GameContract} from "shared/config/blockChain";
import {configOneRound, CONFIG_CLAIM} from "../../config";
import {isWinnerHelper} from "shared/lib/isWinner";
import {encryptionStatus} from "shared/config/sharedType";
import {catchErrorNotification, NotificationType} from "shared/lib/notification";
import {RoundStatus} from "shared/lib/isWinner";

export interface bidClaim {
    encryptionStatus:encryptionStatus,
    round:{
        redsCount:number,
        whitesCount:number,
        roundId:number,
        round:{
            status:RoundStatus,
            game:{
                id:string | number,
            }
        }
    },
    decryptedVote:boolean,
}

export const useClaim = () => {
    const {address} = useAccount();
    const configClaim = useQuery(CONFIG_CLAIM, {
        variables:{address, id:'0', gameId:0} // not_mock
    });

    const { data: signer } = useSigner();

    const contractGame = useContract({
        ...GameContract,
        signerOrProvider: signer,
    })

    const claimCall = (bid:bidClaim) => async () => {
        
        if(!contractGame) return;

        if(bid.encryptionStatus !== encryptionStatus.DECRYPTED) return null;

        const {roundId, round} = bid.round

        const dataConfig = await configClaim.refetch({
            id:roundId.toString(),
            gameId:+round.game.id
        })

        const {data} = dataConfig;
        const roundSK = data.game.rounds?.[0];

        const player = data.game.rounds?.[0].players?.[0]
        const expectedUserValue = player.redsCount

        const claimConfig:configOneRound = {
            expectedValue:roundSK.expectedValue,
            secretKey:roundSK?.secretKey ?? "",
            expectedUserValue,
        };

        const decryptedRound = {
            roundId,
            gameId:round?.game.id ?? '0',
            ...claimConfig,
        }
        
        try{
            
            const callContractClaim = await contractGame?.claim(
                +decryptedRound!.gameId,
                +decryptedRound!.roundId,
                +decryptedRound!.expectedValue,
                +decryptedRound!.expectedUserValue,
                decryptedRound!.secretKey
            );

        } catch(e:any) {
            console.log(e)
            catchErrorNotification(
				e,
				NotificationType.ERROR
			)
        }


    }

    return {
        claimCall
    }
}
