import {
  FooterContainer,
  FooterLinks,
  Links,
  ReservedText,
} from "./footer.styled";
import { linksRulesPlatform } from "shared/config/routeConfig";

export interface FooterProps {
  isLanding?: boolean;
}

export const Footer = ({ isLanding }: FooterProps) => {
  return (
    <FooterContainer>
      <FooterLinks>
        {linksRulesPlatform.map((link, index) => (
          <Links to={link.link} key={index}>
            {link.title}
          </Links>
        ))}
      </FooterLinks>

      <ReservedText isLanding={isLanding}>
        © 2023 All Rights Reserved
      </ReservedText>
    </FooterContainer>
  );
};
