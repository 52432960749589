import { transformProps, textProps } from "shared/config/sharedType";
import { CircleInfo, Item, ItemText } from "../styled";

interface BetProps extends textProps {
	bet: transformProps.BET_RED | transformProps.BET_WHITE;
}

export const Bet = ({ text, bet }: BetProps) => (
	<Item>

		<CircleInfo color={
			bet === transformProps.BET_WHITE ? "#FCFBFB" : '#CE4242'
		} />

		<ItemText>{text}</ItemText>
	</Item>
)
