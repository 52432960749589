import styled from "styled-components"

export const RaffleParticipantsWinner = styled.div`
	margin-top: 16px;
`
export const RaffleParticipantsWinnerInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	width: 100%;
	padding: 0 8px;
`
export const RaffleParticipantsWinnerPlace = styled.div`
	margin-left: 10px;

	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #E4EBFA;

	@media (max-width: 1500px) {
		font-size: 14px;
		line-height: 14px;
	};
`
export const RaffleParticipantsWinnerAddress = styled.div`
	display: flex;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;

	color: #E4EBFA;

	@media (max-width: 1500px) {
		font-size: 12px;
		line-height: 14px;
	};

`
export const RafflePlayed = styled.div`
`
export const RaffleParticipantsWinnerValue = styled.div`
	@media (max-width: 1500px) {
		font-size: 12px;
		line-height: 14px;
	};

`