import { useStore } from "effector-react";

import {
  Container,
  Links,
  LinksImage,
  Linktext,
  LogoMenu,
  Menu,
  MenuLinks,
  MenuSocLinks,
  SocLink,
} from "./MainNavbar.styled";
import { LinksMenu } from "../config";
import { IconUI } from "shared/ui/Icon";
import { AppRoutes, landingRoute, router } from "shared/config/routeConfig";
import logo from "shared/assets/images/smallLogo.svg";
import { socLinks } from "shared/config/cosLinks";

export const MainNavbar = () => {
  const path = useStore(router.$path);

  return (
    <Container>
      <Menu>
        <LogoMenu to={landingRoute}>
          <IconUI src={logo} />
        </LogoMenu>

        <MenuLinks>
          {LinksMenu.map((link) => {
            const isActive = path === link.link;
            return (
              <Links key={link.link} to={link.path} isActive={isActive}>
                <LinksImage>{link.image}</LinksImage>

                <Linktext>{link.title}</Linktext>
              </Links>
            );
          })}
        </MenuLinks>

        <MenuSocLinks>
          {socLinks.map((icon, index) => (
            <SocLink key={icon.link} to={icon.link}>
              {icon.img}
            </SocLink>
          ))}
        </MenuSocLinks>
      </Menu>
    </Container>
  );
};