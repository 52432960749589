import {useEffect} from "react";
import {useStore} from "effector-react";
import { useAccount } from "wagmi";
import bs58 from "bs58";


import {router} from "shared/config/routeConfig";
import {REFERRER_ITEM, REFERRER_KEY} from "shared/config/referral";
import {LazyLayout} from "shared/ui/Loading";

export const addReferrer = (component: () => React.ReactNode) => (() => {
    const query = useStore(router.$query);
    const {address} = useAccount();

    useEffect(() => {
        const {referrer} = query;

        if(!referrer) return; 
        const addressReffer = `0x${Buffer.from(bs58.decode(referrer)).toString("hex")}`;

        if(
            referrer 
            && 
            !REFERRER_ITEM
            &&
            REFERRER_ITEM !== "undefined"
            &&
            addressReffer.toLocaleLowerCase() !== address?.toLocaleLowerCase()
        ) {
            localStorage.setItem(REFERRER_KEY, referrer);
        }
    }, [query, address])

    return <LazyLayout>{component()}</LazyLayout>
})