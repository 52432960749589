
export const convertTimer = (date:string | number) => {
    return +date < 10 ? `0${date}` : date
}

export const getDate = (date:Date) => {
    return `${convertTimer(date.getHours())}:${convertTimer(date.getMinutes())}`
}

export const convertDate = (deadline:string | number) => {
    const date = new Date(+deadline * 1000 ?? 0);
    const dateFormat = `${convertTimer(date.getDate())}-${convertTimer(date.getMonth() + 1)}-${String(date.getFullYear()).replace("20", '')} ${getDate(date)}`

    return dateFormat;
}