import styled, {css} from "styled-components";

export const BodyCardCSS = String(css`
  width: 100%;
  height: 100%;
`)

export const BodyCard = styled.div`
    ${BodyCardCSS}
`

export const ActiveGame = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px 24px;

    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const Title = styled.h2`
    font-weight: 600;
    font-size: var(--font-size-medium);
    line-height: 20px;
    color: var(--font-color-white100);
`

export const BodyActiveGame = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`

export const InfoActiveGame = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
`

export const TitleInfoActiveGame = css`
    font-weight: 600;
    font-size: 24px;
    line-height: var(--font-size-x-large);
`

export const InfoActiveGameBlue = styled.h3`
    color: var(--font-color-blue50);
`

export const InfoActiveGameWhite = styled.h3`
    color: var(--font-color-white100);
`

export const TextInfoActiveGame = styled.p`
    font-weight: 400;
    font-size: var(--font-size-small);
    line-height: 14px;
    color: var(--font-color-blue75);
`