
import {useQuery} from "@apollo/client";

import {CardsPrevious, LAST_GAMES} from "../../config";
import {convertTimer} from "shared/lib/formatted";
import {isWinColor} from "shared/lib/isWinner";



export const useGetLast = () => {
    const {data} = useQuery(LAST_GAMES);

    if(!data?.games) return [];

    const lastGames:CardsPrevious[] = data?.games.map(({rounds, id}:any) => {

        const round = rounds[0];

        if(!round) return undefined;


        const rewardLevelPercent = +((parseInt(round.rewardLevel) / 100).toFixed(0));

        const dateSeconds = new Date(+round.deadline * 1000 ?? 0);
        const date = `${convertTimer(dateSeconds.getDate())}.${convertTimer(dateSeconds.getMonth() + 1)}, ${convertTimer(dateSeconds.getHours())}:${convertTimer(dateSeconds.getMinutes())}`

        return{
            id:+round?.roundId ?? 0,
            gameId:+id,
            date,
            participated:+round?.playersCount ?? 0,
            winBid:isWinColor(round.status).text,
            rewardLevel:rewardLevelPercent,
        }
    }).filter((game: CardsPrevious | undefined) => Boolean(game))

    return lastGames;
}