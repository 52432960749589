import { Link } from "atomic-router-react";

import { Button } from "shared/ui/Button";
import { CardTypeBG } from "entities/Games/config";
import { CardType, ColorType } from "shared/config/gamesTypes";
import { transformColor, transformTypeCard } from "shared/lib/formatted";
import { ButtonTheme } from "shared/ui/Button/Button";
import { AppRoutes, rulesRoute } from "shared/config/routeConfig";

import {ButtonBid, ButtonConfrim, MiniText} from '../../CardGame.styled';
import {
    Body,
    ChooseColorBody,
    ChooseColorText,
    ColorRed,
    ColorWhite,
    FormBody,
    FormIput,
    FormItem,
    IReadBody,
    IReadInput,
    RulesReadText,
} from './styled';
import {useFormBet} from "../../../../../model";

interface SelectColorProps {
  typeCard: CardType;
  gameId: number;
  roundId: number;
  setClose: () => void;
}

export const BetPlaced = ({
  typeCard,
  gameId,
  roundId,
  setClose,
}: SelectColorProps) => {
  const {
    currentColor: [activeColor, changeActiveColor],
    rules: [rules, changeRules],
    confirm,
  } = useFormBet(typeCard, gameId, roundId, setClose);

  return (
    <Body>
      <ChooseColorText>Choose your favourite color</ChooseColorText>

      <ChooseColorBody>
        <ColorRed
          onClick={changeActiveColor(ColorType.RED)}
          isActive={activeColor === ColorType.RED}
          color={CardTypeBG[typeCard]}
        />

        <ColorWhite
          onClick={changeActiveColor(ColorType.WHITE)}
          isActive={activeColor === ColorType.WHITE}
          color={CardTypeBG[typeCard]}
        />
      </ChooseColorBody>

      <FormBody>
        <FormItem>
          <MiniText>Your choise is</MiniText>

          <FormIput value={transformColor(activeColor)} disabled />
        </FormItem>

        <FormItem>
          <MiniText>Your bid is</MiniText>

          <FormIput
            value={`${transformTypeCard(typeCard).price} BNB`}
            disabled
          />
        </FormItem>
      </FormBody>

      <RulesReadText>To place a bet, please read the rules</RulesReadText>
      <IReadBody>
        <IReadInput type="checkbox" checked={rules} onChange={changeRules} />
        <span>
          I have read{" "}
          <Link to={rulesRoute} style={{ color: "#6EB9D4" }}>
            Rules
          </Link>{" "}
          and agree
        </span>
      </IReadBody>

      <ButtonConfrim />
      <ButtonBid>
        <Button
          onClick={confirm}
          theme={
            rules ? transformTypeCard(typeCard).theme : ButtonTheme.NOT_ACTIVE
          }
        >
          Confirm
        </Button>
      </ButtonBid>
    </Body>
  );
};
