
import styled, {css} from "styled-components";
import {typeStatistic} from './statistic';

export const BodyGameCSS = String(css`
  height: 150px;
  @media(max-width: 1300px) {
    width: 100%;
  }
`)

export const BodyGame = styled.div
    .attrs((props: {typeStatistic:typeStatistic}) => props)`
  ${BodyGameCSS};
  
  @media(max-width: 1300px) {
    max-width: ${(props) => props.typeStatistic === 'General' && '500px'} ;
    width: 100%;
    margin: 0 auto;
  }
`

export const GameDetails = styled.div`
	padding: 15px 15px;
`

export const GameStatisticsBodyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const GameStatisticsTitle = styled.h1`
	padding: 0 5px;

	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
`

export const GameStatistics = styled.div`
	margin-top: 15px;

	display: flex;
	justify-content: space-between;

	padding: 0 6px;
`

export const GameFieldValues = styled.div`
	display: flex;
	flex-direction: column;
	gap: 18px;

	padding-right: 20px;
`
export const GameFieldNames = styled.div`
	display: flex;
	flex-direction: column;
	gap: 18px;
`
export const GameFieldName = styled.div`
	color: #7B859F;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
`
export const GameFieldValue = styled.div`
	color: #E4EBFA;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
`