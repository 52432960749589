import Countdown from "react-countdown";

import {
  Raffle,
  RaffleEndTime,
  RaffleImg,
  RaffleText,
  SkeletonCss,
} from "./header.styled";

import raffle from "../img/raffle.png";

import { AppRoutes, raffleRoute } from "shared/config/routeConfig";
import { IconUI } from "shared/ui/Icon";
import { rendererTimerRaffle } from "shared/ui/timer";
import { useGetCurrentRound } from "entities/Raffle";
import { Skeleton } from "shared/ui/Skeleton";

export const RaffleHeader = () => {
  const data = useGetCurrentRound();

  return (
    <Skeleton isLoading={!Boolean(data.deadline)} cssBody={SkeletonCss}>
      <Raffle to={raffleRoute}>
        <RaffleImg>
          <IconUI src={raffle} />
        </RaffleImg>

        <RaffleText>Raffle</RaffleText>

        <RaffleEndTime>
          <Countdown date={data.deadline} renderer={rendererTimerRaffle} />
        </RaffleEndTime>
      </Raffle>
    </Skeleton>
  );
};
