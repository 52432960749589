import {useSubscription} from "@apollo/client";
import {useAccount} from "wagmi";

import {BALANCE_TICKETS} from "../../config";

export const useTicketsUser = () => {
    const {address} = useAccount()
    const {data} = useSubscription(BALANCE_TICKETS, {
        variables:{address}
    });

    if(!data?.root) return undefined;

    const players = data?.root?.raffleCurrentRound?.players;

    return typeof (players) !== 'undefined' &&
        players.length > 0 ?

        players?.[0].tickets : '0';
}