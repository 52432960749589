import {transformProps} from "shared/config/sharedType";
import {GameStatus} from "shared/config/gamesTypes";

export enum RoundStatus {
    NOT_STARTED="NOT_STARTED",
    IN_PROGRESS="IN_PROGRESS",
    RED="RED",
    WHITE="WHITE",
    NOBODY="NOBODY"
}

interface isWinnerColor {
    status: RoundStatus
}

interface isWinnerProps extends isWinnerColor{
    decryptedVote:boolean
}

interface isWinnerReturn {
    text:string,
    element:transformProps,
}

export const isWinnerHelper = ({
    status,
    decryptedVote
}:isWinnerProps):isWinnerReturn => {
    if(status===RoundStatus.NOBODY) return {
        text:'Draw',
        element: transformProps.STATUS_LOOSE
    };

    const winnerColor = status === RoundStatus.RED;
    const isWinner = winnerColor === decryptedVote;

    const win = {
        text:'Win',
        element: transformProps.STATUS_WIN
    }

    const loose = {
        text:'Loose',
        element: transformProps.STATUS_LOOSE
    }

    return isWinner ? win : loose;
}

export const isWinColor = (status:GameStatus):isWinnerReturn => {
    if(status === GameStatus.DRAW) return {
        text:'Draw',
        element: transformProps.TEXT
    }

    const winWhite = {
        text:'White',
        element: transformProps.BET_WHITE
    }

    const winRed = {
        text:'Red',
        element: transformProps.BET_RED
    }

    const winnerColor = status === GameStatus.RED ? winRed : winWhite;

    return winnerColor;
}