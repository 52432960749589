import styled, {keyframes} from "styled-components";

const animationNotification = keyframes`
    0% {
        right:-268px;
    }
    
    10%{
      right:24px;
    }

      85%{
        right:24px;
      }
    
    100%{
      right:-268px;
    }
`

export const BodyNotification = styled.div
    .attrs((props:{theme:string}) => props)`
  width: 240px;
  min-height: 46px;
  position: fixed;
  z-index: 10000;
  right: 24px;
  top: 76px;
  border-radius:12px;
  border: 1px solid ${props => props.theme};
  background: var(--font-color-blue100);
  animation: 7s ${animationNotification} ease-in-out forwards;
  
  display: flex;
  flex-direction: column;
  padding: 16px 24px;

  font-weight: 400;
  font-size: var(--font-size-small);
  line-height: 14px;
  color: ${props => props.theme};
`

export const NotificationText = styled.p
    .attrs((props:{color:string}) => props)`
  &:not(:first-child) {
    margin-top: 8px;
  }
  color:${props => props.color && props.color}
`



