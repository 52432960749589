import {createEvent, createStore} from "effector";


export function filtersState<PropsT>() {
    const changeFilters = createEvent<null | number>();
    const $filters = createStore<null | number>(null)

    $filters.on(changeFilters, (state, value) => value === state ? null : value)

    return { changeFilters, $filters };
}
