import {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {formatEther} from "viem";
import {currentRaffleTypes} from "entities/Raffle";
import {INFO_RAFFLE} from "entities/Raffle/config";
import {useAccount} from "wagmi";
import {convertAddress, formattedIntegers} from "shared/lib/formatted";
import {useStore} from "effector-react";
import {$timeCurrent} from "shared/lib/timeCurrent";



const initData:currentRaffleTypes =  {
    roundId:undefined,
    deadline:0,
    amount:0,
    tickets:0,
    winningAmount:0,
    playerTickets:0,
    winners: {
        winner1st:'',
        winners:[],
    }
}

export const useGetCurrentRound = () => {
    const {address} = useAccount();
    const {data} = useQuery(INFO_RAFFLE, {
        variables: {address: address ?? '0x'}
    });
    const time = useStore($timeCurrent);

    if(!data) return initData;


    const raffleCurrentRound = data.root.raffleCurrentRound;
    const jackpot = data.root.raffleCurrentRound.jackpot;
    const user = raffleCurrentRound?.players;

    console.log()

    const raffle = {
        roundId: raffleCurrentRound.id,
        deadline: +raffleCurrentRound.deadline * 1000,
        amount: +formattedIntegers(+formatEther(data.root.jackpotBank) + +formatEther(raffleCurrentRound.amount)),
        tickets: raffleCurrentRound.ticketsSum,
        playerTickets: user.length !== 0 ? user?.[0].tickets : '0',
        winningAmount: +formattedIntegers(formatEther(raffleCurrentRound.winningAmount)),
        winners: {
            winner1st: jackpot.lenght ? convertAddress(jackpot[0].player.id) : 'none',
            winners: raffleCurrentRound.winners.map((winner: any) => ({
                address: winner.player.id,
                amount: formattedIntegers(formatEther(winner.amount),)
            })),
        }
    }

    return raffle;
}