
import styled from 'styled-components';
import { IconUI } from '../Icon';

export const ModalContainer = styled.div`
    padding: 36px 24px 24px 24px;
    position: relative;
    z-index: 111111;
    
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`


export const CloseBtn = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;

		${IconUI} svg path {
			fill: #E4EBFA;
		}

    cursor: pointer;

		transition: all .3s ease-in;
		
		&:hover svg path {
			transition: all .3s ease-in;
			stroke: #E4EBFA;
		}
`   