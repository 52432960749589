import styled from "styled-components";
import { MenuMobileProps, theme } from "./config";
import { isActive } from "shared/config/sharedType";
import { Link } from "atomic-router-react";
import { SocLinksImg } from "shared/ui/Icon";

/*MENU BTN*/

export const MenuOpen = styled.button.attrs((props: isActive) => props)`
  height: 20px;
  width: 28px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) =>
    props.isActive &&
    `
    position:absolute;
    top:24px;
    right:12px;
  `}
`;

export const MenuOpenInside = styled.div.attrs(
  (props: { theme: theme }) => props
)`
  height: 2px;
  width: 100%;
  background: #ededed;
`;

/*MENU*/

export const MenuWrapper = styled.div.attrs((props: MenuMobileProps) => props)`
  position: fixed;
  z-index: 40000;
  top: 0;
  left: ${(props) => (props.isActive ? "0" : "-200%")};
  transition: left 0.5s;

  width: 100%;
  height: 100%;
  background: #0a0a0a;

  padding: 24px 12px 42px 12px;

	overflow-y: scroll;
`;

export const MainLinksBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 28px;
  margin-top: 69px;
`;

export const MainLinks = styled(Link)`
  color: #ededed;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  :hover {
    transition: color 0.3s ease-in;

    color: #61a9fa;
  }
`;

export const Br = styled.div`
  width: 100%;
  margin: 32px 0;
  height: 2px;
  background: var(--font-color-blue75);
  opacity: 0.5;
`;

export const LinksRulesPlatformBody = styled.div`
  padding: 0 38px;
  margin-top: 48px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LinkRulesPlatform = styled(Link).attrs(
  (props: { theme: theme }) => props
)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--font-color-white100);
`;

export const SocLinksBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
  margin: 0 auto;
  margin-top: 48px;
`;

export const SocLinks = styled(Link)`
  width: 24px;
  height: 21px;
  ${SocLinksImg}
`;

export const BottomWeb3 = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  margin-top: 20px;

  & button {
    width: 150px;
  }
`;

export const WalletConnectBody = styled.div`
  width: 150px;
`;