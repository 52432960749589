import ReactDOM from 'react-dom/client';
import App from './app';
import 'app/styles/index.css';
import { Buffer } from 'buffer';


// @ts-ignore
window.Buffer = Buffer;

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
