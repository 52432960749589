import { Button, ButtonTheme } from '../Button/Button';
import {TabBtn, TabText} from './Tab.styled';
import { TabType } from './config/types';

interface TabProps {
	tab: TabType<any>;
	onChangeFilter: (tab: any) => void;
	active: boolean;
}

export const Tab = (props: TabProps) => {
	const { tab, onChangeFilter, active } = props;

	return (
		<TabBtn>
			<Button
				theme={active ? ButtonTheme.OUTLINED_BLUE : ButtonTheme.GRAY_DEFAULT}
				onClick={() => onChangeFilter(tab)}
			>
				<TabText>{tab.text}</TabText>
			</Button>
		</TabBtn>
	);
};
