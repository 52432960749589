import styled from "styled-components"
import { MiniText } from "../../CardGame.styled"
import { isActive } from 'shared/config/sharedType';

export const Body = styled.div`
    display: flex;
  flex-direction: column;
  align-items: center;
    width: 100%;
  order: 4;
`

export const ChooseColorBody = styled.div`
    display: flex;
    align-items: center;
    gap:48px;
    margin-top: 12px;
`

interface ColorBodyProps extends isActive{
    color:string
}

export const ColorBody = styled.div
    .attrs((props: ColorBodyProps) => props)`
    width: 48px;
    height: 48px;
    border: ${props => props.isActive ? `2px solid ${props.color}` : '1px solid #7B859F'};
    border-radius: 50%;

    position: relative;

		transition: all .1s ease-in;

		&:hover {
			border: ${props => `2px solid ${props.color}`};
		}

    &::after {
        content:'';
        width: 4px;
        height: 4px;

        position:absolute;
        bottom:-9px;
        left:50%;
        transform: translateX(-50%);

        background-color: ${props => props.isActive && props.color};
        border-radius: 50%;
    }
`

export const ColorRed = styled(ColorBody)`
    background: #CE4242;
`   

export const ColorWhite = styled(ColorBody)`
    background: #FCFBFB;
`   

export const FormBody = styled.div`
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap:66px;
`

export const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const FormIput = styled.input`
    width: 104px;
    height: 32px;
    background: #2A3554;

    padding:4px 12px;

    border: 1px solid #7B859F;
    border-radius: 6px;
  
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #E4EBFA;
`

export const RulesReadText = styled(MiniText)`
    margin-top: 28px;
`

export const IReadBody = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap:8px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #E4EBFA;
`

export const IReadInput = styled.input`
    width:14px;
    height:14px;
`

export const ChooseColorText = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #E4EBFA;
    margin-top: 24px;
`

