import {gql} from "@apollo/client";


export const GAME_STATISTIC_USER = gql(`
    query GAME_STATISTIC_USER($address: String) {
      gamePerPlayers(where:{player:$address}, orderBy:game__id) {
        id
        roundsCount
        bidsCount
        game {
          id
        }
        aggregate{
          winRoundsCount
          winningAmount
        }
      }
    }
`)

export type {GameStatisticUser, gamePerPlayers, dataInfo} from './type';
