
import {css} from "styled-components"

import {Table} from "shared/ui/Table";
import {useisMobile} from "shared/lib/isMobile";
import {useMergePagination} from "shared/lib/pagination";
import {useRewardsRounds} from "entities/user/model";

const gameMobileCSS = String(css`
  grid-template-columns: repeat(7, 11.9658%);
  justify-content: space-between;

  @media (max-width:768px) {
    grid-template-columns: 12% 12% 12% 12% 20% 12% 15%;
  }
`).substring(1)

export const RewardActivity = () => {
    const isMobile = useisMobile();
    const {pagination, data} = useMergePagination(useRewardsRounds);

    return(
      <Table
            title='Bets'
            items={data.items}
            headersTitle={[["Date", "Date"], ["ID game", "ID"], ["Mode", "Mode"], ["Bet", "Bet"], ["Status", "Status"], ["Reward", "Reward"], [" ", " "]]}
            gridCss={gameMobileCSS}
            ignoredColumns={isMobile ? "23" : ""}
            {...pagination}
      />
    )
}