import {useQuery} from "@apollo/client";
import {useAccount} from "wagmi";
import {formatEther} from "viem";

import {dataInfo, GAME_STATISTIC_USER, gamePerPlayers, GameStatisticUser} from "../../config";
import {formattedIntegers} from "shared/lib/formatted";
import {CardType} from "shared/config/gamesTypes";

const cardsType = [CardType.CLASSIC, CardType.PREMIUM, CardType.ULTIMATE];

const gamesStatisticDefault = cardsType.map((CardType, index) => ({
    myBids: 0,
    winRate: 0,
    TotalReward: 0,
    CardType,
}));



const gamesStatisticLoading = cardsType.map((CardType) => ({
    myBids: undefined,
    winRate: undefined,
    TotalReward: undefined,
    CardType
}))

export const useTypeGameStatistic = (): dataInfo[] => {
    const {address} = useAccount()

    const {data} = useQuery<GameStatisticUser>(GAME_STATISTIC_USER, {
        variables: {address}
    });

    if (!data) return gamesStatisticLoading;

    if (data?.gamePerPlayers?.length === 0) return gamesStatisticDefault;

    return data.gamePerPlayers
        .map(({aggregate: {winRoundsCount, winningAmount}, bidsCount, roundsCount, game}: gamePerPlayers, index: number) => {
            const myBids = +formattedIntegers(bidsCount);
            const winRounds = +formattedIntegers(winRoundsCount);

            return {
                myBids,
                winRate: winRounds > 0 ? +formattedIntegers(winRounds * 100 / +roundsCount) : 0,
                // @ts-ignore
                TotalReward: +formattedIntegers(+formatEther(winningAmount)),
                CardType: cardsType[index],
            }
        });
}
