
import styled, { css } from "styled-components";

export const WrapperCard = styled.div
	.attrs((props: { css: string }) => props)`
    ${props => props.css};
`

export const ContentCard = styled.div`
    padding: 16px 24px;
    width:100%;
    height:100%;
`

export const Title = styled.h2`
    font-weight: 600;
    font-size: var(--font-size-medium);
    line-height: 20px;
    color: var(--font-color-white100);
`

export const ContentWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

  @media(max-width: 1300px) {
    align-items: center;
  }
  
`

export const ContentItems = styled.div
	.attrs((props: { css: string }) => props)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  ${props => props.css};
  margin-right: 15px;

  @media(max-width: 1300px) {
    margin-top: 12px;
  }
`

export const ItemStatistic = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`

const cssText = css`
    font-weight: 400;
    font-size: var(--font-size-small);
    line-height: 14px;
`;

export const TitleStatistic = styled.h3
	.attrs((props: { css: string }) => props)`
    
    ${cssText};
    ${props => props.css};
  margin-right: 10px;
`

export const InfoStatistic = styled.h3`
    ${cssText};
`

export const ContainerGrapth = styled.div
	.attrs((props: { css: string }) => props)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    

    ${props => props.css}

    // &::before {
    //     content: "";
    //     position: absolute;
    //     inset: 0;
    //     border-radius: 50%;
    //     padding: 20px; 
    //     background: 
    //         linear-gradient(#CE4242 79% , #FCFBFB 21%)
    //     ;
    //     -webkit-mask: 
    //         linear-gradient(#fff 0 0) content-box, 
    //         linear-gradient(#fff 0 0);

    //     -webkit-mask-composite: xor;
    //             mask-composite: exclude;
    //     pointer-events: none;
    // }
`

