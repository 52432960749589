import {Item, ItemLinkText, LinkTypeGame} from "../styled";

import { textProps } from "shared/config/sharedType";
import {AppRoutes} from "shared/config/routeConfig";
import {transformIntToName} from "shared/lib/formatted";

interface LinksProps extends textProps {
    idGame:0 | 1 | 2;
}

export const Links = ({text, idGame}: LinksProps) => (
    <Item>
      <ItemLinkText to={`${AppRoutes.SINGLE_STATISTICS}${transformIntToName(idGame).title}/${text}`}>
          {text}
          <LinkTypeGame idGame={idGame}/>
      </ItemLinkText>
    </Item>
)
